import { Component } from '@angular/core';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from './user/service/user.service';

type LangItem = { code: string; label: string };

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  languageList: LangItem[] = [
    { code: 'fi', label: 'Suomeksi' },
    { code: 'sv', label: 'På Svenska' },
    { code: 'en', label: 'In English' },
  ];

  constructor(
    public userService: UserService,
    private router: Router,
    private translate: TranslateService
  ) {
    translate.addLangs(this.languageList.map((x) => x.code));
    translate.setDefaultLang('fi');
    translate.use('fi');
  }

  logout() {
    this.userService.logout().subscribe(
      () => this.router.navigate(['login', {logout: true}])
    );
  }

  changeLanguage(langCode: string) {
    this.translate.use(langCode);
  }
}
