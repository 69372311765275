/* src/app/statement/statement-text-component/statement-text.component.scss */
.statement-text-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.statement-text-section .statement-text h3 {
  font-family: "Source Sans Pro";
  font-size: 28px;
  font-weight: 600;
  color: #212529;
  margin: 20px 0 34px 0;
}
.statement-text-section .statement-text p {
  font-family: "Source Sans Pro";
  font-size: 18px;
  color: #212529;
  line-height: 27px;
}
.statement-text-section .statement-category {
  margin-top: 8px;
}
.statement-text-section .buttons {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10px;
}
.statement-text-section .buttons button {
  margin-right: 20px;
}
/*# sourceMappingURL=statement-text.component.css.map */
