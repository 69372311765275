import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import {map} from 'rxjs/operators';
import {UserService} from './user/service/user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {
  constructor(
    private userService: UserService,
    private router: Router
  ) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.userService.refreshCurrentUser().pipe(
      map(user => {
        if (user) {
          // Only internal users allowed to internal frontpage; redirect others to landing page
          if (next.routeConfig.path == 'internal' && !this.userService.isInternalUser()) {
            this.router.navigateByUrl('/');
            return false;
          }

          return true;
        }

        this.router.navigate(['login', {redirect: state.url}]);
        return false;
      })
    );
  }
}
