<div class="page">
  <div class="page-content">
    <h2 translate="application.addApplication"></h2>
    <form *ngIf="methodForm" [formGroup]="methodForm" (ngSubmit)="loadLaboratory()">
      <div class="method columns extra-pad" [ngClass]="{disabled: methodForm.disabled}">
        <div>
          <h3 translate="application.forExistingLaboratory"></h3>
          <div class="stacked-input">
            <label for="referenceNumber" translate="application.searchByReferenceNumber"></label>
            <app-searchable-drop-down
              [items]="laboratories"
              [searchFields]="['name', 'referenceNumber']"
              [keyField]="'id'"
              (selectEvent)="selectEvent($event)"
            />
          </div>
        </div>
        <div>
          <h3 translate="application.forNewLaboratory"></h3>
          <div class="stacked-input">
            <label>&nbsp;</label>
            <button type="button" class="add-laboratory" (click)="newLaboratory()" [disabled]="(!methodForm || methodForm.disabled)">
              <i class="pi pi-plus-circle"></i><span translate="laboratory.addNew"></span>
            </button>
          </div>
        </div>
      </div>
    </form>
    <div class="box" [hidden]="! laboratory">
      <h2 *ngIf="laboratory && laboratory.properties.id">{{ laboratory.properties.name }}</h2>
      <h2 *ngIf="laboratory && ! laboratory.properties.id" translate="laboratory.addNew"></h2>
      <h3 translate="laboratory.basicInfo"></h3>
      <app-laboratory-form
        [laboratory]="laboratory"
        (saved)="moveToLaboratoryView()"
        (cancelled)="onCancelLaboratoryEdit()"
        (edited)="moveToLaboratoryEdit()"
      ></app-laboratory-form>
    </div>
    <form
      class="application"
      *ngIf="applicationForm"
      [formGroup]="applicationForm"
      (ngSubmit)="saveApplication()"
      [ngClass]="{disabled: applicationForm.disabled}"
    >
      <div class="columns">
        <div class="stacked-input">
          <label for="aviDiary" translate="application.aviDiary"></label>
          <app-validation-error-message [control]="applicationForm.get('aviDiary')"></app-validation-error-message>
          <input type="text" id="aviDiary" [formControl]="applicationForm.get('aviDiary')" required />
        </div>

        <div class="stacked-input">
          <label for="avi" translate="application.avi"></label>
          <app-validation-error-message [control]="applicationForm.get('aviId')"></app-validation-error-message>
          <select id="avi" [formControl]="applicationForm.get('aviId')" required>
            <option [value]="''"></option>
            <option *ngFor="let avi of avis" [ngValue]="avi.properties.id">{{ avi.properties.name }}</option>
          </select>
        </div>
        <div class="stacked-input"></div>
      </div>
      <div class="columns">
        <div class="stacked-input">
          <label translate="application.category"></label>
          <app-validation-error-message [control]="applicationForm.get('category')"></app-validation-error-message>
          <div class="button-group">
            <button
              type="button"
              class="narrow"
              [ngClass]="{selected: applicationForm.get('category').value === 1}"
              (click)="applicationForm.get('category').setValue(1)"
              [disabled]="applicationForm.disabled"
            >1</button>
            <button
              type="button"
              class="narrow"
              [ngClass]="{selected: applicationForm.get('category').value === 2}"
              (click)="applicationForm.get('category').setValue(2)"
              [disabled]="applicationForm.disabled"
            >2</button>
            <button
              type="button"
              class="narrow"
              [ngClass]="{selected: applicationForm.get('category').value === 3}"
              (click)="applicationForm.get('category').setValue(3)"
              [disabled]="applicationForm.disabled"
            >3</button>
          </div>
        </div>

        <div class="stacked-input">
          <label for="supervisingLaboratory" translate="application.supervisingLaboratory"></label>
          <app-validation-error-message
            [control]="applicationForm.get('supervisingLaboratoryId')"
            [hideIfUntouched]="false"
          ></app-validation-error-message>
          <div class="input-with-button">
            <input
              type="number"
              id="supervisingLaboratory"
              [placeholder]="translateService.instant('application.changeByRefNum')"
              [(ngModel)]="supervisingLaboratoryRefNum"
              [ngModelOptions]="{standalone: true}"
              (keydown.enter)="changeSupervisingLaboratory($event)"
            />
            <button
              type="button"
              (click)="changeSupervisingLaboratory($event)"
              translate="set"
              [disabled]="! supervisingLaboratoryRefNum"
            ></button>
          </div>
          <label class="current-supervising-laboratory">
            <strong
              [id]="'supervising-laboratory-' + application.with.supervisingLaboratory.properties.id"
              *ngIf="application.with.supervisingLaboratory; else notPresent"
            >
              {{ application.with.supervisingLaboratory.properties.name }}
            </strong>
            <ng-template #notPresent>
              <em translate="notPresent"></em>
            </ng-template>
            <span
              class="link"
              translate="remove"
              (click)="removeSupervisingLaboratory()"
              *ngIf="application.with.supervisingLaboratory"
            ></span>
          </label>
        </div>

        <div class="stacked-input"></div>
      </div>
      <div class="columns">
        <div class="stacked-input">
          <label for="arrivalDate" translate="application.arrivalDate"></label>
          <app-validation-error-message [control]="applicationForm.get('arrivalDate')"></app-validation-error-message>
          <input type="date" id="arrivalDate" [formControl]="applicationForm.get('arrivalDate')" />
        </div>

        <div class="stacked-input">
          <label for="requestDate" translate="application.requestDate"></label>
          <app-validation-error-message [control]="applicationForm.get('requestDate')"></app-validation-error-message>
          <input [readonly]="true"  type="date" id="requestDate" [formControl]="applicationForm.get('requestDate')" />
        </div>

        <div class="stacked-input"></div>
      </div>
      <div class="inspection-of-requirements">
        <label class="checkbox">
          <input type="checkbox" id="inspectingRequirements" [formControl]="applicationForm.get('inspectingRequirements')" />
          {{ 'application.inspectingRequirements' | translate }}
        </label>
      </div>
      <div class="buttons">
        <button
          type="submit"
          [disabled]="applicationForm.invalid || applicationForm.disabled"
          translate="application.saveAndStart"
        ></button>
      </div>
    </form>
  </div>
</div>
