import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {Subject} from 'rxjs';
import {Laboratory} from '../../../laboratory/model/laboratory';
import {debounceTime, switchMap, tap} from 'rxjs/operators';
import {LaboratoryFind} from '../../../laboratory/model/laboratory-find';
import {DistinctLaboratoryNameFind} from '../../model/distinct-laboratory-name-find';
import {LaboratoryService} from '../../../laboratory/service/laboratory.service';
import {SubcontractService} from '../../service/subcontract.service';
import {Subcontract} from '../../model/subcontract';
import {Research} from '../../model/research';
import * as _ from 'lodash';

@Component({
  selector: 'app-application-researches-subcontract-panels',
  templateUrl: './application-researches-subcontract-panels.component.html',
  styleUrls: ['./application-researches-subcontract-panels.component.scss'],
  exportAs: 'applicationResearchesSubcontractPanelsComponent'
})
export class ApplicationResearchesSubcontractPanelsComponent implements OnInit {
  @Input()
  subcontracts: Subcontract[];

  @Input()
  formArray: UntypedFormArray;

  @Output()
  researchRemoved = new EventEmitter<Research>();

  @Output()
  subcontractRemoved = new EventEmitter<Subcontract>();

  laboratorySearchTerms = new Subject<string>();
  searchTerm: string;

  laboratoryResults: Laboratory[] = [];
  laboratoryNameResults: string[] = [];
  activeLaboratoryIndex: number;

  constructor(
    private laboratoryService: LaboratoryService,
    private subcontractService: SubcontractService,
    private formBuilder: UntypedFormBuilder
  ) {}

  ngOnInit() {
    this.subcontracts
      .map(this.buildSubcontractGroup.bind(this))
      .forEach((group: UntypedFormGroup) => this.formArray.push(group));

    const commonPipe = this.laboratorySearchTerms.pipe(
      tap(term => this.searchTerm = term),
      debounceTime(300)
    );

    commonPipe.pipe(
      switchMap(term => {
        const searchFind = new LaboratoryFind();
        searchFind.limit = 10;
        searchFind.where.nameStartsWith = term;

        return this.laboratoryService.find(searchFind);
      })
    ).subscribe(laboratoryResults => this.laboratoryResults = laboratoryResults);

    commonPipe.pipe(
      switchMap(term => {
        const searchFind = new DistinctLaboratoryNameFind();
        searchFind.limit = 10;
        searchFind.where.nameStartsWith = term;

        return this.subcontractService.findDistinctLaboratoryName(searchFind);
      })
    ).subscribe(laboratoryNameResults => this.laboratoryNameResults = laboratoryNameResults);
  }

  buildSubcontractGroup(subcontract: Subcontract) {
    let name = subcontract.properties.unknownLaboratoryName;
    if (subcontract.with.laboratory) {
      name = subcontract.with.laboratory.properties.name;
    }

    return this.formBuilder.group({
      laboratory: [name],
      researches: this.formBuilder.array([])
    });
  }

  selectLaboratory(laboratoryIndex: number, subcontractIndex: number, group: UntypedFormGroup) {
    const subcontract = this.subcontracts[subcontractIndex];

    let laboratoryName;
    if (laboratoryIndex < this.laboratoryResults.length) {
      if (this.isForeign(subcontract)) {
        return;
      }

      const laboratory = this.laboratoryResults[laboratoryIndex];
      laboratoryName = laboratory.properties.name;

      subcontract.with.laboratory = laboratory;
      subcontract.properties.laboratoryId = laboratory.properties.id;
    } else if (laboratoryIndex <= this.laboratoryResults.length + this.laboratoryNameResults.length) {
      if (this.isDomestic(subcontract)) {
        return;
      }

      laboratoryName = this.laboratoryNameResults[laboratoryIndex - this.laboratoryResults.length];
      if (laboratoryIndex === this.laboratoryResults.length + this.laboratoryNameResults.length) {
        laboratoryName = this.searchTerm;
      }

      subcontract.properties.unknownLaboratoryName = laboratoryName;
    }

    group.get('laboratory').setValue(laboratoryName);
  }

  addNew() {
    const subcontract = new Subcontract();
    subcontract.with.researches = [];

    this.subcontracts.push(subcontract);
    this.formArray.push(this.buildSubcontractGroup(subcontract));
  }

  removeSubcontract(index: number) {
    const subcontract = _.pullAt(this.subcontracts, index)[0];
    if (subcontract.properties.id) {
      subcontract.with.researches.forEach(research => this.researchRemoved.emit(research));
      this.subcontractRemoved.emit(subcontract);
    }

    this.formArray.removeAt(index);
  }

  formGroups() {
    return this.formArray.controls as UntypedFormGroup[];
  }

  isDomestic(subcontract: Subcontract) {
    return !! subcontract.properties.laboratoryId;
  }

  isForeign(subcontract: Subcontract) {
    return !! subcontract.properties.unknownLaboratoryName;
  }
}
