<div *ngIf="signerOptions" class="signer">
  <div *ngIf="edit">
    <input type="text" [(ngModel)]="editableSigner" />
    <div class="buttons">
      <button type="submit" (click)="saveSigner()" class="save-button">{{ 'statement.save' | translate }}</button>
      <button type="submit" (click)="cancelSigner()">{{ 'statement.cancel' | translate }}</button>
    </div>
  </div>
  <div *ngIf="!edit">
    <div><span *ngIf="signerOptions.length > 0">{{signerOptions[signerIndex].signer}}</span></div>
    <a (click)="modify()">{{ 'statement.edit' | translate }}</a>
  </div>
  <select (ngModelChange)="signerSelectChange($event)" [(ngModel)]=signerIndex [disabled]="edit">
    <option *ngFor="let signer of signerOptions; let index = index" [ngValue]="index">{{signer.signer}}</option>
  </select>
</div>
