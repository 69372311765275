/* src/app/application/component/application-header/application-header.component.scss */
.box-bordered {
  margin-top: 18px;
  padding-top: 18px;
}
.box-bordered .page-content .columns {
  margin-bottom: 75px;
  max-width: 655px;
}
.box-bordered .page-content .columns > div {
  width: 50%;
}
.box-bordered .page-content .columns > div > * {
  display: block;
}
.box-bordered .page-content .columns > div:last-child {
  text-align: right;
}
.box-bordered .page-content .columns .category {
  margin: 9px 0;
}
.box-bordered .page-content .columns .category .circle {
  display: inline-block;
  text-align: center;
  width: 28px;
  border: 3px solid #C3C2C6;
  border-radius: 14px;
  line-height: 22px;
  font-weight: 700;
}
.buttons {
  display: flex;
  margin-top: -47px;
}
.buttons > * {
  flex-grow: 1;
}
.buttons > * a {
  width: 100%;
}
/*# sourceMappingURL=application-header.component.css.map */
