/* src/app/application/component/application-researches/application-researches.component.scss */
.box {
  padding: 24px;
}
.box .header {
  align-items: center;
  margin-bottom: 23px;
}
.box .header h2 {
  margin-top: 0;
}
.box .header p {
  color: #303030;
  margin-bottom: 0;
}
.box .header button {
  white-space: nowrap;
  display: flex;
  align-items: center;
}
.box .header button:disabled {
  color: grey;
  cursor: not-allowed;
  opacity: 0.5;
}
.box h3 {
  font-weight: 600;
  text-transform: uppercase;
}
.box .own-researches .panel-header {
  padding: 16px 25px 12px 25px;
}
/*# sourceMappingURL=application-researches.component.css.map */
