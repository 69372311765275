<div class="top" *ngIf="userService.isLoggedIn()">
  <div class="tolu-text">
    <span class="first" translate="layout.TOLUFirstLine"></span>
    <span class="second" translate="layout.TOLUSecondLine"></span>
  </div>
  <img src="assets/img/logo.png" />
  <div class="menu">
    <a *ngIf="userService.isInternalUser()" routerLink="/internal" translate="layout.mainMenu.internalFrontpage"></a>
    <a routerLink="/" translate="layout.mainMenu.landing"></a>
    <a routerLink="/laboratory" translate="layout.mainMenu.laboratories"></a>
    <a (click)="logout()" translate="layout.mainMenu.logout" class="logout"></a>
    <select
      #selectedLang
      (change)="changeLanguage(selectedLang.value)">
      <option *ngFor="let language of languageList" [value]="language.code">{{language.label}}</option>
    </select>
  </div>
</div>
<router-outlet></router-outlet>
<p-toast></p-toast>
