/* src/app/statement/statement.component.scss */
.statement-page-content {
  width: 210mm;
  margin: auto;
}
.preview-rectangle-header,
.preview-rectangle-footer {
  height: 52px;
  background-color: #FAA61A;
}
.preview-rectangle-header .statement-not-yet-saved,
.preview-rectangle-footer .statement-not-yet-saved {
  font-family: "Source Sans Pro";
  font-size: 16px;
}
.preview-rectangle-header button,
.preview-rectangle-footer button {
  box-sizing: border-box;
  height: 40px;
  font-family: "Source Sans Pro";
  font-size: 15px;
  line-height: 16px;
}
.preview-rectangle-header .back-to-edit-mode-button,
.preview-rectangle-footer .back-to-edit-mode-button {
  background-color: #fafafa;
  border: 1px solid #C3C2C6;
  color: #000000;
}
.preview-rectangle-header {
  margin-bottom: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.preview-rectangle-header > div:first-child {
  margin: 0 130px 0 12px;
  font-family: "Source Sans Pro";
  font-size: 24px;
  font-weight: 600;
}
.preview-rectangle-header > .statement-not-yet-saved {
  margin-right: 12px;
}
.preview-rectangle-footer {
  margin-top: 14px;
  display: flex;
  align-items: center;
}
.preview-rectangle-footer > .statement-not-yet-saved {
  margin: 0 30px 0 12px;
}
.statement-created-notification {
  margin: 30px 0 20px 2px;
  font-family: "Source Sans Pro";
  font-size: 28px;
}
.buttons-for-draft-statement {
  display: flex;
  align-items: center;
}
.buttons-for-draft-statement .back-to-edit-mode-button {
  margin-right: 15px;
}
.statement {
  position: relative;
  width: 210mm;
  height: 297mm;
  background-color: white;
  padding: 1.7cm 1.4cm 1.7cm 1.4cm;
}
.statement .columns > div:first-child {
  width: 50%;
}
.statement .columns > div:nth-child(2) {
  width: 25%;
}
.statement .columns > div:last-child {
  width: 25%;
}
.statement .columns .statement-date-label,
.statement .columns .thl-dnro-label {
  font-size: 19px;
  font-weight: 600;
}
.statement .columns .statement-date-label {
  text-transform: uppercase;
}
.statement .columns .statement-date {
  margin-top: 20px;
  font-size: 18px;
}
.statement .columns .statement-security-classification {
  margin-top: 20px;
  color: #DC3545;
  font-weight: 800;
  text-align: center;
}
.statement .columns .thl-dnro {
  margin-top: 3px;
  font-size: 16px;
}
.statement .logo {
  width: 40%;
  margin: -15px 0 0 -15px;
}
.statement .avi-contact {
  margin-top: 40px;
}
.statement .licence-applicant {
  margin-top: 60px;
  padding: 20px 20px 20px 20px;
  border: 2px solid #f2f2f2;
  border-left: 2px solid #7bc143;
}
.statement .licence-applicant .laboratory-name {
  margin-top: 5px;
  font-size: 22px;
  font-weight: 600;
}
.statement .licence-applicant .columns {
  margin-top: 20px;
}
.statement .licence-applicant .columns > div:first-child {
  width: 27%;
}
.statement .licence-applicant .columns > div:nth-child(2) {
  width: 38%;
}
.statement .licence-applicant .columns > div:last-child {
  width: 35%;
}
.statement .licence-applicant .label {
  font-size: 17px;
}
.statement .statement-text h3 {
  font-family: "Source Sans Pro";
  font-size: 22px;
  font-weight: 600;
  margin: 60px 0 60px 0;
}
.statement .statement-text p {
  padding-left: 80px;
}
.statement .signatures {
  margin-top: 150px;
}
.statement .signatures > div:first-child {
  padding-left: 45px;
  width: 50%;
}
.statement .signatures > div:last-child {
  width: 50%;
}
.statement .statement-footer {
  position: absolute;
  bottom: 30px;
  padding-left: 63px;
}
.statement .statement-footer > div {
  text-align: center;
}
.statement .statement-footer .thl-name {
  font-size: 12px;
  font-weight: 600;
}
.statement .statement-footer .thl-address {
  margin-top: -3px;
  font-size: 12px;
}
.statement .statement-footer .thl-twitter {
  font-size: 14px;
  font-weight: 600;
  color: #7bc143;
}
.buttons-for-ready-statement {
  display: flex;
  align-items: center;
  margin: 10px 0 5px 0;
}
.buttons-for-ready-statement .modify-link {
  margin-left: 2px;
  margin-right: 250px;
  text-decoration: underline;
  font-family: "Source Sans Pro";
  font-size: 14px;
  color: #2F62AD;
}
@media print {
  .preview-rectangle-header,
  .preview-rectangle-footer,
  .buttons-for-ready-statement,
  .statement-created-notification {
    display: none;
  }
  @page {
    size: A4;
  }
  .statement {
    position: unset;
    padding: 0 !important;
  }
  .statement-footer {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0 !important;
  }
}
/*# sourceMappingURL=statement.component.css.map */
