<h1 translate="login.TOLU"></h1>
<div class="page-content narrow">
  <app-message *ngIf="logout">
    <span translate="login.youHaveLoggedOut"></span>
  </app-message>
  <div class="box">
    <h2 translate="login.login"></h2>

    <app-message [closeable]="false" type="error" *ngIf="incorrect">
      <span translate="login.wrongCredentials"></span>
    </app-message>

    <form [formGroup]="form" (ngSubmit)="login()">
      <div class="stacked-input">
        <label for="username" translate="login.username"></label>
        <input type="text" id="username" formControlName="username" #username required />
      </div>

      <div class="stacked-input">
        <label for="password" translate="login.password"></label>
        <input type="password" id="password" formControlName="password" required />
      </div>

      <div class="buttons">
        <button type="submit" [disabled]="form.invalid" translate="login.login"></button>
      </div>
    </form>

    <a href="#" translate="login.lostPassword"></a>
    <img src="assets/img/logo.png" alt="{{ 'login.THL' | translate }}" />
  </div>
</div>
