import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Laboratory} from '../../model/laboratory';
import {UntypedFormBuilder, Validators} from '@angular/forms';
import {Util} from '../../../base/util';
import {LaboratoryService} from '../../service/laboratory.service';
import {MessageService} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-laboratory-form',
  templateUrl: './laboratory-form.component.html',
  styleUrls: ['./laboratory-form.component.scss']
})
export class LaboratoryFormComponent implements OnInit {
  private privateLaboratory: Laboratory;

  @Input()
  set laboratory(value: Laboratory) {
    this.privateLaboratory = value;

    if (value) {
      Util.entityPropertiesToForm(this.laboratory, this.form);
    }
  }

  get laboratory() {
    return this.privateLaboratory;
  }

  @Output()
  saved = new EventEmitter<void>();

  @Output()
  cancelled = new EventEmitter<void>();

  @Output()
  edited = new EventEmitter<void>();

  form = this.formBuilder.group({
    address: ['', Validators.required],
    city: ['', Validators.required],
    name: ['', Validators.required],
    businessIdentityCode: [''],
    billingContact: [''],
    organisationName: ['', Validators.required],
    organisationAddress: ['', Validators.required],
    organisationPostalCode: ['', Validators.required],
    organisationCity: ['', Validators.required],
    phoneNumber: ['', Validators.required],
    postalCode: ['', Validators.required],
    terminated: ['', Validators.required]
  });

  constructor(
    private formBuilder: UntypedFormBuilder,
    private laboratoryService: LaboratoryService,
    private messageService: MessageService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.form.disable();

    // Hack to update forms validness after server errors.
    // TODO: Should not be needed: https://juristr.com/blog/2019/02/display-server-side-validation-errors-with-angular/
    this.form.valueChanges.subscribe(value => {
      this.form.setValue(value, {emitEvent: false});
    });
  }

  disable() {
    this.form.disable();
  }

  enable() {
    this.form.enable();
    this.form.markAsUntouched();
  }

  onEditClick() {
    this.edited.emit();
    this.enable();
  }

  save() {
    Util.formToEntityProperties(this.form, this.laboratory);

    this.laboratoryService.save(this.laboratory.properties).subscribe(response => {
      if (! response.validation.valid) {
        this.messageService.add({
          severity: 'error',
          summary: this.translateService.instant('laboratory.saveFailed')
        });

        Util.errorsToForm(response, this.form);

        return;
      }

      this.messageService.add({
        severity: 'success',
        summary: this.translateService.instant('laboratory.saveSucceed')
      });

      this.laboratory.properties = response.properties;

      this.saved.emit();
      this.form.disable();
    });
  }

  cancel() {
    Util.entityPropertiesToForm(this.laboratory, this.form);
    this.cancelled.emit();

    this.form.disable();
  }
}
