/* src/app/application/component/searchable-drop-down/searchable-drop-down.component.scss */
#searchable-dropdown {
  position: relative;
  display: flex;
  flex-direction: column;
}
#searchable-dropdown input {
  height: 24px;
  width: 200px;
  padding: 5px;
  font-size: 0.75em;
  border: 1px solid black;
}
#searchable-dropdown ul {
  position: absolute;
  top: 100%;
  width: 390px;
  background-color: #fff;
  list-style: none;
  padding: 0;
  margin-top: 0;
  border: 1px solid #ccc;
  border-top: none;
  max-height: 200px;
  overflow-y: scroll;
}
#searchable-dropdown ul li {
  padding: 5px;
  cursor: pointer;
}
#searchable-dropdown ul li:hover {
  background-color: #f0f0f0;
}
/*# sourceMappingURL=searchable-drop-down.component.css.map */
