/* src/app/internal-frontpage/internal-frontpage.component.scss */
.header {
  text-align: center;
}
.header h1 {
  margin-top: 30px;
}
h2 {
  margin-bottom: 8px;
}
.columns > * {
  width: 50%;
}
.table-wrapper {
  padding: 24px;
}
.table-wrapper table {
  width: 100%;
  border-collapse: collapse;
  min-height: 1000px;
}
.table-wrapper table tr {
  height: 78px;
}
.table-wrapper table tr td,
.table-wrapper table tr th {
  vertical-align: center;
  text-align: left;
  padding: 18px;
  border-bottom: 1px solid #C3C2C6;
}
/*# sourceMappingURL=internal-frontpage.component.css.map */
