/* src/app/landing/landing.component.scss */
.header {
  text-align: center;
}
.header h1 {
  margin-top: 30px;
}
h2 {
  margin-bottom: 8px;
}
.columns > * {
  width: 50%;
}
.in-progress {
  padding: 24px;
}
.in-progress table {
  width: 100%;
  border-collapse: collapse;
}
.in-progress table tr td,
.in-progress table tr th {
  vertical-align: center;
  text-align: left;
  padding: 28px;
  border-bottom: 1px solid #C3C2C6;
}
.search {
  margin-top: 12px;
  padding: 24px;
}
.search ul {
  padding: 0;
  list-style: none;
}
/*# sourceMappingURL=landing.component.css.map */
