/* src/app/application/component/application-researches/application-researches-research-table.component.scss */
table {
  border-collapse: collapse;
}
table > thead > tr > th {
  padding: 18px 8px 3px 8px;
}
table > thead > tr.first-row {
  text-align: left;
}
table > thead > tr.first-row > th {
  padding-left: 20px;
}
table > thead > tr.second-row {
  text-transform: uppercase;
  color: #606060;
}
table > thead > tr.second-row > th {
  border-bottom: 1px solid #C3C2C6;
  padding-right: 20px;
  padding-top: 0;
}
table > tbody > tr > td {
  padding: 18px 8px;
}
table > tbody > tr > td input:not([type=checkbox]) {
  width: 100%;
}
table > tbody > tr > td:first-child {
  padding-left: 20px;
}
table > tbody > tr > td:last-child {
  padding-right: 20px;
}
table > tbody > tr > td.number {
  width: 10%;
}
table > tbody > tr > td.abbreviation {
  width: 15%;
}
table > tbody > tr > td.name {
  width: 30%;
}
table > tbody > tr > td.amount {
  width: 10%;
}
table > tbody > tr > td.qualityControl {
  width: 10%;
}
table > tbody > tr > td.labQuality {
  width: 5%;
}
table > tbody > tr > td.ukneqas {
  width: 5%;
}
table > tbody > tr > td.qcmd {
  width: 5%;
}
table > tbody > tr > td.accredited {
  width: 10%;
}
table > tbody > tr > td.remove {
  width: 1px;
  font-size: 32px;
  text-align: center;
  cursor: pointer;
}
table > tbody > tr.validation-row > td {
  padding-bottom: 0;
}
table > tbody > tr.input-row > td {
  border-bottom: 1px solid #C3C2C6;
}
table.narrow > tbody > tr > td.number {
  width: 20%;
}
table.narrow > tbody > tr > td.abbreviation {
  width: 20%;
}
table.narrow > tbody > tr > td.name {
  width: 40%;
}
table.narrow > tbody > tr > td.amount {
  width: 20%;
}
.actions {
  margin: 15px 0 70px 25px;
}
.actions .button {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  font-weight: 600;
  margin-right: 15px;
}
.actions .button i {
  font-size: 30px;
}
.actions .button input[type=file] {
  display: none;
}
.research-dropdown {
  width: 546px;
}
.research-dropdown table {
  width: 100%;
  border-collapse: collapse;
  cursor: pointer;
  border-bottom: 1px solid #DCDFE2;
}
.research-dropdown table tr {
  height: 56px;
}
.research-dropdown table tr.active {
  background-color: #EDF0F2;
}
.research-dropdown table tr td {
  padding: 0 12px;
}
.research-dropdown .add-new-term {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 13px 0;
}
.research-dropdown .add-new-term i {
  font-size: 30px;
  margin: 0 16px;
}
.research-dropdown .add-new-term.active {
  background-color: #EDF0F2;
}
/*# sourceMappingURL=application-researches-research-table.component.css.map */
