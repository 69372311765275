<div *ngIf="application" id="register-extract-page-content">
  <div class="register-extract-header">
    <div class="register-extract-created-notification">
      {{ 'registerExtract.registerExtractCreatedNotification' | translate: {
        thlIdentifier: (application.with.statement) ? application.with.statement.thlDiary : '',
        time: dateOfRegisterExtract  | timeonly,
        date: dateOfRegisterExtract | dateonly
      } }}
    </div>
    <div class="buttons-for-register-extract">
      <button type="submit" translate="print" (click)="print()" class="print-button"></button>
      <div class="exit">
        <a href="javascript:void(0)" (click)="goBack()" class="exit-link">{{ 'exit' | translate }}</a>
      </div>
    </div>
  </div>
  <div class="register-extract-doc-content">
    <div class="doc-header">
      <div><img src="assets/img/thl_logo_short.png" class="logo" /></div>
      <div class="doc-title">
        <div>{{ 'registerExtract.docTitle' | translate }}</div>
        <div class="register-extract-security-classification">
          <div class="restricted-use">{{ 'securityClassification.restrictedUse' | translate }}</div>
          <div class="security-level">{{ 'securityClassification.securityLevel' | translate }}</div>
        </div>
      </div>
    </div>

    <div id="register-extract-basic-data">
      <div>
        <div class="lab-name">{{ application.with.laboratory.properties.name }}</div>
        <div class="licence-basic-data">
          <div>
            <span>{{ 'registerExtract.date' | translate }}</span>
            <span>{{ dateOfRegisterExtract | dateonly }}</span>
          </div>
          <div>
            <span>{{ 'registerExtract.referenceNumber' | translate }}</span>
            <span>{{ application.with.laboratory.properties.referenceNumber }}</span>
          </div>
          <div>
            <span>{{ 'registerExtract.categoryOfLicense' | translate }}</span>
            <span>{{ categoryOfLicense(application.properties.category) }}</span>
          </div>
          <div>
            <span>{{ 'registerExtract.aviDiary' | translate }}</span>
            <span>{{ application.properties.aviDiary }}</span>
          </div>
          <div>
            <span>{{ 'registerExtract.thldnro' | translate }}</span>
            <span>{{ (application.with.statement) ? application.with.statement.thlDiary : '' }}</span>
          </div>
          <div>
            <span>{{ 'registerExtract.licenseValidUntil' | translate }}</span>
            <span>{{ 'registerExtract.recordedInDecisionOfAVI' | translate }}</span>
          </div>
        </div>

        <div class="contact-information-title">{{ 'registerExtract.contactInformation' | translate }}</div>
        <div class="contact-information">
          <div>
            <span>{{ 'registerExtract.address' | translate }}</span>
            <span>{{ application.with.laboratory.properties.address }}</span>
          </div>
          <div>
            <span>{{ 'registerExtract.postalCode' | translate }}</span>
            <span>{{ application.with.laboratory.properties.postalCode }}</span>
          </div>
          <div>
            <span>{{ 'registerExtract.city' | translate }}</span>
            <span>{{ application.with.laboratory.properties.city }}</span>
          </div>
          <div>
            <span>{{ 'registerExtract.phoneNumber' | translate }}</span>
            <span>{{ application.with.laboratory.properties.phoneNumber }}</span>
          </div>
        </div>
      </div>

      <div>
        <div class="persons-in-charge-title">{{ 'registerExtract.personalData' | translate }}</div>
        <div class="persons-in-charge">
          <div *ngIf="chiefInChargeOfClinicalMicrobiologyBusiness">
            <span>{{ 'contact.roles.ChiefInChargeOfClinicalMicrobiologyBusiness' | translate }}</span>
            <span>{{ chiefInChargeOfClinicalMicrobiologyBusiness.name }}</span>
          </div>
          <div *ngIf="personInChargeOfDailyLaboratoryBusiness">
            <span>{{ 'contact.roles.PersonInChargeOfDailyLaboratoryBusiness' | translate }}</span>
            <span>{{ personInChargeOfDailyLaboratoryBusiness.name }}</span>
          </div>
        </div>
      </div>
    </div>

    <ng-container *ngIf="supervisedCategory3Labs.length > 0">
      <div *ngFor="let chunk of chunkLaboratories(supervisedCategory3Labs); let pageIndex = index">
        <div class="header-for-researches">
          <div><img src="assets/img/thl_logo_short.png" class="logo" /></div>
          <div class="lab-name-and-researches-title-and-date">
            <div class="lab-name">
              {{ application.with.laboratory.properties.name }},
              {{ application.with.laboratory.properties.referenceNumber }}
            </div>
            <div class="researches-title-and-date">
              <div class="researches-title">{{ 'registerExtract.supervision' | translate }}</div>
              <div class="report-date">{{ dateOfRegisterExtract | dateonly }}</div>
            </div>
          </div>
        </div>

        <div class="supervised-labs-title">
          {{
          'registerExtract.supervisedCategory3Labs' |
            translate: {count: countStringForLaboratories(supervisedCategory3Labs, pageIndex)}
          }}
        </div>
        <div class="supervised-labs">
          <div *ngFor="let lab of chunk">
            <span>{{lab.properties.referenceNumber}}</span>
            <span>{{lab.properties.name}}</span>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="supervisedCategory2Labs.length > 0">
      <div *ngFor="let chunk of chunkLaboratories(supervisedCategory2Labs); let pageIndex = index">
        <div class="header-for-researches">
          <div><img src="assets/img/thl_logo_short.png" class="logo" /></div>
          <div class="lab-name-and-researches-title-and-date">
            <div class="lab-name">
              {{ application.with.laboratory.properties.name }},
              {{ application.with.laboratory.properties.referenceNumber }}
            </div>
            <div class="researches-title-and-date">
              <div class="researches-title">{{ 'registerExtract.supervision' | translate }}</div>
              <div class="report-date">{{ dateOfRegisterExtract | dateonly }}</div>
            </div>
          </div>
        </div>

        <div class="supervised-labs-title">
          {{
          'registerExtract.supervisedCategory2Labs' |
            translate: {count: countStringForLaboratories(supervisedCategory2Labs, pageIndex)}
          }}
        </div>
        <div class="supervised-labs">
          <div *ngFor="let lab of chunk">
            <span>{{lab.properties.referenceNumber}}</span>
            <span>{{lab.properties.name}}</span>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="application.with.researches.length > 0">
      <div *ngFor="let researches of chunkResearches(application.with.researches); let pageIndex = index">
        <div class="header-for-researches">
          <div><img src="assets/img/thl_logo_short.png" class="logo" /></div>
          <div class="lab-name-and-researches-title-and-date">
            <div class="lab-name">
              {{ application.with.laboratory.properties.name }},
              {{ application.with.laboratory.properties.referenceNumber }}
            </div>
            <div class="researches-title-and-date">
              <div class="researches-title">{{ 'registerExtract.researches' | translate }}</div>
              <div class="report-date">{{ dateOfRegisterExtract | dateonly }}</div>
            </div>
          </div>
        </div>

        <div class="researches">
          <div class="researches-title">
            {{
              'registerExtract.inHouseProduction' |
              translate: {count: countStringForResearches(application.with.researches, pageIndex)}
            }}
          </div>
          <table>
            <thead>
            <tr class="top-th-cells">
              <th>{{ 'registerExtract.researchNumber' | translate }}</th>
              <th [innerHTML]="'registerExtract.abbreviationOfResearch' | translate"></th>
              <th [innerHTML]="'registerExtract.nameOfReaearch' | translate"></th>
              <th class="amount">{{ 'registerExtract.amountPerYear' | translate }}</th>
              <th [innerHTML]="'registerExtract.qualityControl' | translate"></th>
              <th colspan="3" [innerHTML]="'registerExtract.externalQualityControl' | translate"></th>
              <th [innerHTML]="'registerExtract.accredited' | translate"></th>
            </tr>
            <tr class="bottom-th-cells">
              <th colspan="5"></th>
              <th>{{ 'registerExtract.labQuality' | translate }}</th>
              <th>{{ 'registerExtract.ukneqas' | translate }}</th>
              <th>{{ 'registerExtract.qcmd' | translate }}</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let research of researches">
              <td><span *ngIf="!research.properties.researchCodeOfKuntaliitto">*</span>{{research.properties.number}}</td>
              <td><span *ngIf="!research.properties.researchCodeOfKuntaliitto">*</span>{{research.properties.abbreviation}}</td>
              <td><span *ngIf="!research.properties.researchCodeOfKuntaliitto">*</span>{{research.properties.name}}</td>
              <td class="amount">{{research.properties.amount}}</td>
              <td>{{(research.properties.qualityControl ? 'registerExtract.yes' : 'registerExtract.no') | translate}}</td>
              <td>{{research.properties.labQuality}}</td>
              <td>{{research.properties.ukneqas}}</td>
              <td>{{research.properties.qcmd}}</td>
              <td>{{(research.properties.accredited ? 'registerExtract.yes' : 'registerExtract.no') | translate}}</td>
            </tr>
            </tbody>
          </table>
          <div class="explain" translate="registerExtract.notificationAboutResearchCodes"></div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="foreignSubcontractResearches().length > 0">
      <div *ngFor="let researches of chunkResearches(foreignSubcontractResearches()); let pageIndex = index">
        <div class="header-for-researches">
          <div><img src="assets/img/thl_logo_short.png" class="logo" /></div>
          <div class="lab-name-and-researches-title-and-date">
            <div class="lab-name">
              {{ application.with.laboratory.properties.name }},
              {{ application.with.laboratory.properties.referenceNumber }}
            </div>
            <div class="researches-title-and-date">
              <div class="researches-title">{{ 'registerExtract.researches' | translate }}</div>
              <div class="report-date">{{ dateOfRegisterExtract | dateonly }}</div>
            </div>
          </div>
        </div>

        <div class="researches">
          <div class="researches-title">
            {{
              'registerExtract.foreignSubcontracts' |
              translate: {count: countStringForResearches(foreignSubcontractResearches(), pageIndex)}
            }}
          </div>
          <table>
            <thead>
              <tr class="top-th-cells">
                <th>{{ 'registerExtract.researchNumber' | translate }}</th>
                <th [innerHTML]="'registerExtract.abbreviationOfResearch' | translate"></th>
                <th [innerHTML]="'registerExtract.nameOfReaearch' | translate"></th>
                <th class="amount">{{ 'registerExtract.amountPerYear' | translate }}</th>
                <th>{{ 'registerExtract.nameOfSubcontractor' | translate }}</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let research of researches">
                <td><span *ngIf="!research.properties.researchCodeOfKuntaliitto">*</span>{{research.properties.number}}</td>
                <td><span *ngIf="!research.properties.researchCodeOfKuntaliitto">*</span>{{research.properties.abbreviation}}</td>
                <td><span *ngIf="!research.properties.researchCodeOfKuntaliitto">*</span>{{research.properties.name}}</td>
                <td class="amount">{{research.properties.amount}}</td>
                <td>{{research.with.subcontract.properties.unknownLaboratoryName}}</td>
              </tr>
            </tbody>
          </table>
          <div class="explain" translate="registerExtract.notificationAboutResearchCodes"></div>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="register-extract-footer">
    <div class="buttons-for-register-extract">
      <button type="submit" translate="print" (click)="print()" class="print-button"></button>
      <div class="exit">
        <a href="javascript:void(0)" (click)="goBack()" class="exit-link">{{ 'exit' | translate }}</a>
      </div>
    </div>
  </div>

  <div class="thl-contact-info-for-print">
    <div class="thl-name" translate="registerExtract.footerName"></div>
    <div class="thl-address" translate="registerExtract.footerAddress"></div>
    <div class="thl-twitter" translate="registerExtract.footerTwitter"></div>
  </div>
</div>
