import {Injectable} from '@angular/core';
import {Service} from '../../base/service/service';
import { HttpClient } from '@angular/common/http';
import {SaveAllResponse} from '../../base/model/save-all-response';
import {Subcontract} from '../model/subcontract';
import {SubcontractProperties} from '../model/subcontract-properties';
import {SubcontractSaveResponse} from '../model/subcontract-save-response';
import {DistinctLaboratoryNameFind} from '../model/distinct-laboratory-name-find';

@Injectable({
  providedIn: 'root'
})
export class SubcontractService extends Service<Subcontract> {
  constructor(
    public http: HttpClient
  ) {
    super(
      'api/subcontract',
      http
    );
  }

  findDistinctLaboratoryName(find: DistinctLaboratoryNameFind) {
    return this.http.post<string[]>(this.baseUrl + '/find-distinct-laboratory-name', find);
  }

  save(properties: SubcontractProperties) {
    return this.http.post<SubcontractSaveResponse>(this.baseUrl + '/save', properties);
  }

  saveAll(propertiesList: SubcontractProperties[]) {
    return this.http.post<SaveAllResponse<SubcontractSaveResponse>>(this.baseUrl + '/saveAll', propertiesList);
  }

  deleteAll(ids: number[]) {
    return this.http.delete<boolean>(this.baseUrl, {
      params: {ids: ids.map(id => id.toString())}
    });
  }
}
