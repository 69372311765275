import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Service} from '../../base/service/service';
import {SaveAllResponse} from '../../base/model/save-all-response';
import {Tally} from '../model/tally';
import {TallyProperties} from '../model/tally-properties';
import {TallySaveResponse} from '../model/tally-save-response';

@Injectable({
  providedIn: 'root'
})
export class TallyService extends Service<Tally> {
  constructor(
    public http: HttpClient
  ) {
    super(
      'api/tally',
      http
    );
  }

  save(properties: TallyProperties) {
    return this.http.post<TallySaveResponse>(this.baseUrl + '/save', properties);
  }

  saveAll(propertiesList: TallyProperties[]) {
    return this.http.post<SaveAllResponse<TallySaveResponse>>(this.baseUrl + '/saveAll', propertiesList);
  }
}
