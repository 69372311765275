import {AbstractControl} from '@angular/forms';

export function phoneNumberValidator(control: AbstractControl) {
  if (! control.value || /^[+]?[0-9 ]*$/.test(control.value)) {
    return null;
  }

  return {phoneNumber: true};
}

export function mustBeNullValidator(control: AbstractControl) {
  if (control.value) {
    return {mustBeNull: true};
  }

  return null;
}

export function notOnlyWhitespace(control: AbstractControl) {
  if(control.value && /^\s*$/.test(control.value)) {
    return {notValid: true};
  }

  return null;
}
