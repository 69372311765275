<div *ngIf="statement" class="statement-page-content">
  <div *ngIf="statement.properties.draft" class="preview-rectangle-header">
    <div>{{ 'statement.preview' | translate }}</div>
    <div class="buttons-for-draft-statement">
      <button class="back-to-edit-mode-button" type="submit" translate="statement.back-to-edit-mode" (click)="toEditMode()"></button>
      <button type="submit" (click)="createStatement()">{{ 'statement.createStatement' | translate }}</button>
    </div>
    <div class="statement-not-yet-saved">{{ 'statement.statement-not-yet-saved' | translate }}</div>
  </div>
  <div *ngIf="!statement.properties.draft" class="statement-created-notification">
      {{ 'statement.statementCreated' | translate }} {{ statement.properties.statementDate | dateonly }}
  </div>
  <div class="statement">
    <div class="columns">
      <div>
        <img src="assets/img/thl_logo_short.png" class="logo" />
      </div>
      <div>
        <div class="statement-date-label">{{ 'statement.statement' | translate }}</div>
        <div class="statement-date">{{ statement.properties.statementDate | dateonly }}</div>
        <div class="statement-security-classification">
          {{ 'securityClassification.restrictedUse' | translate }}
          {{ 'securityClassification.securityLevel' | translate }}
        </div>
      </div>
      <div>
          <div class="thl-dnro-label">{{ 'statement.thldnro' | translate }}</div>
          <div class="thl-dnro">THL {{ statement.properties.thlDiary }}</div>
      </div>
    </div>
    <p class="avi-contact"><span [innerHTML]="statement.properties.aviContactInfo"></span></p>

    <div class="statement-text" [innerHTML]="statement.properties.statementText"></div>

    <div class="licence-applicant">
      <div>{{ 'statement.licenceApplicant' | translate }}</div>
      <div class="laboratory-name">{{statement.properties.laboratoryName}}</div>
      <div class="columns">
        <div>
          <div class="label">{{ 'statement.referenceNumber' | translate }}</div>
          <div>{{statement.properties.laboratoryReferenceNumber}}</div>
        </div>
        <div>
            <div class="label">{{ 'statement.requestDateLong' | translate }}</div>
            <div>{{statement.properties.requestDate  | dateonly}}</div>
        </div>
        <div>
            <div class="label">{{ 'statement.aviDiaryLong' | translate }}</div>
            <div>{{statement.properties.aviDiary}}</div>
        </div>
      </div>
    </div>

    <div class="signatures columns">
      <div class="signer-1st">{{statement.properties.firstSigner}}</div>
      <div class="signer-2nd">{{statement.properties.secondSigner}}</div>
    </div>

    <div class="statement-footer">
      <div class="thl-name">Terveyden ja hyvinvoinnin laitos o Institutet for hälsa och välfärd o Finnish Institute for Health and Welfare</div>
      <div class="thl-address">Mannerheimintie 166, Helsinki, Finland + PL/PB/P.O. Box 30, FI-00271 Helsinki + puh/tel +358 29 524 6000</div>
      <div class="thl-twitter">thl.fi + Twitter: &#64;THLorg</div>
    </div>
  </div>
  <div *ngIf="statement.properties.draft" class="preview-rectangle-footer">
    <div class="statement-not-yet-saved">{{ 'statement.statementNotYetSaved' | translate }}</div>
    <div class="buttons-for-draft-statement">
      <button class="back-to-edit-mode-button" type="submit" translate="statement.back-to-edit-mode" (click)="toEditMode()"></button>
      <button type="submit" (click)="createStatement()">{{ 'statement.createStatement' | translate }}</button>
    </div>
  </div>
  <div *ngIf="!statement.properties.draft">
    <div class="buttons-for-ready-statement">
      <a class="modify-link" (click)="toEditMode()">{{ 'statement.toEditMode' | translate }}</a>
      <button type="submit" (click)="print()">{{ 'statement.print' | translate }}</button>
    </div>
  </div>
</div>
