/* src/app/application/component/application-validity/application-validity.component.scss */
.page-content {
  max-width: 700px;
}
.page-content .back {
  padding: 10px 0;
}
.page-content .box {
  padding: 24px;
}
.page-content .box .h2-wrapper {
  display: flex;
}
.page-content .box .h2-wrapper h2 {
  margin-top: 0;
  flex-grow: 1;
}
.page-content .box .h2-wrapper .diary {
  flex-shrink: 0;
  text-align: right;
}
.page-content .box .validity {
  display: flex;
  align-items: baseline;
}
.page-content .box .validity input[type=radio] {
  margin-right: 10px;
}
.page-content .box .validity input[type=date] {
  margin-right: 5px;
}
.page-content .box .validity > *:first-child {
  padding-right: 20px;
  margin-right: 20px;
  border-right: 1px solid #C9C9C9;
}
.page-content .box .footer {
  text-align: center;
}
.page-content .box .footer a {
  display: inline-block;
  margin-top: 12px;
}
/*# sourceMappingURL=application-validity.component.css.map */
