<app-application-header [application]="application"></app-application-header>
<div class="page">
  <div *ngIf="application" class="page-content">
    <form [formGroup]="form" (ngSubmit)="save()">
      <div class="box">
        <div class="header columns">
          <div>
            <h2 translate="research.laboratoryResearchCatalogOfClinicalMicrobiology"></h2>
            <p class="size-18" translate="research.information"></p>
          </div>
          <div>
            <button [disabled]="application.properties.category === 2 || application.properties.category === 3" type="button"
              class="big secondary" (click)="subcontractPanels.addNew()">
              <i class="pi pi-plus-circle"></i>
              <span translate="subcontract.addSubcontract"></span>
            </button>
          </div>
        </div>
        <div class="panel own-researches">
          <div class="panel-header">
            <h3 class="size-16" translate="research.ownResearches"></h3>
          </div>
          <div class="panel-body">
            <app-application-researches-research-table
              [application]="application"
              [formArray]="ownResearchesFormArray()"
              [researches]="application.with.researches"
              [showRedundantFields]="true"
              (researchRemoved)="removedResearches.push($event)"
            ></app-application-researches-research-table>
          </div>
        </div>
        <app-application-researches-subcontract-panels
          #subcontractPanels="applicationResearchesSubcontractPanelsComponent"
          [formArray]="subcontractsFormArray()"
          [subcontracts]="application.with.subcontracts"
          (researchRemoved)="removedResearches.push($event)"
          (subcontractRemoved)="removedSubcontracts.push($event)"
        ></app-application-researches-subcontract-panels>
      </div>
      <div class="buttons">
        <button [disabled]="form.invalid" type="submit" translate="save"></button>
      </div>
    </form>
  </div>
</div>
