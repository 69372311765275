/* src/app/application/component/application-contacts/application-contacts.component.scss */
.box {
  padding: 24px;
}
.box table {
  text-align: left;
}
.box table .input-cell {
  width: 15%;
}
.box table tbody th {
  font-weight: normal;
}
.box table input {
  width: 100%;
}
/*# sourceMappingURL=application-contacts.component.css.map */
