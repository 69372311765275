<div class="panel" *ngFor="let group of formGroups(); let subcontractIndex = index">
  <div class="panel-header">
    <h3 class="size-16">
      <span *ngIf="! isDomestic(subcontracts[subcontractIndex]) && ! isForeign(subcontracts[subcontractIndex])">
        {{ 'research.subcontract' | translate }}:
      </span>
      <span *ngIf="isDomestic(subcontracts[subcontractIndex])">
        {{ 'subcontract.domesticSubcontract' | translate }}:
      </span>
      <span *ngIf="isForeign(subcontracts[subcontractIndex])">
        {{ 'subcontract.foreignSubcontract' | translate }}:
      </span>
      <div class="input">
        <input
          type="text"
          class="inline"
          [formControl]="group.get('laboratory')"
          [appDropdownInput]="laboratoryInputDropdownMenu"
          [length]="laboratoryResults.length + laboratoryNameResults.length + 1"
          [(active)]="activeLaboratoryIndex"
          (focusin)="laboratorySearchTerms.next(laboratoryInput.value)"
          (input)="laboratorySearchTerms.next(laboratoryInput.value)"
          (selection)="selectLaboratory($event, subcontractIndex, group)"
          [placeholder]="'subcontract.addNameHere' | translate"
          #laboratoryInput
        />
      </div>
      <span (click)="removeSubcontract(subcontractIndex)" class="link" translate="remove"></span>
    </h3>
  </div>
  <div class="panel-body">
    <app-application-researches-research-table
      [formArray]="group.get('researches')"
      [researches]="subcontracts[subcontractIndex].with.researches"
      [showRedundantFields]="isForeign(subcontracts[subcontractIndex])"
      (researchRemoved)="researchRemoved.emit($event)"
    ></app-application-researches-research-table>
  </div>
  <div
    class="dropdown-menu subcontract-dropdown"
    appDropdownMenu
    #laboratoryInputDropdownMenu="dropdownMenuDirective"
  >
    <div class="group">
      <h4 class="size-12" translate="subcontract.domesticLicensedLaboratory"></h4>
      <ul>
        <li
          *ngFor="let laboratory of laboratoryResults; let index = index"
          (mouseover)="activeLaboratoryIndex = index"
          (click)="laboratoryInputDropdownMenu.select(index)"
          [ngClass]="{
            active: index === activeLaboratoryIndex,
            disabled: isForeign(subcontracts[subcontractIndex])
          }"
        >
          {{ laboratory.properties.name }}
        </li>
      </ul>
      <p
        *ngIf="isForeign(subcontracts[subcontractIndex])"
        translate="subcontract.cannotChangeForeignLaboratory"
      ></p>
    </div>
    <div class="group">
      <h4 class="size-12" translate="subcontract.foreignSubcontractLaboratory"></h4>
      <ul>
        <li
          *ngFor="let name of laboratoryNameResults; let index = index"
          (mouseover)="activeLaboratoryIndex = laboratoryResults.length + index"
          (click)="laboratoryInputDropdownMenu.select(laboratoryResults.length + index)"
          [ngClass]="{
            active: laboratoryResults.length + index === activeLaboratoryIndex,
            disabled: isDomestic(subcontracts[subcontractIndex])
          }"
        >
          {{ name }}
        </li>
      </ul>
      <div
        class="add-new-term size-16"
        (mouseover)="activeLaboratoryIndex = laboratoryResults.length + laboratoryNameResults.length"
        (click)="laboratoryInputDropdownMenu.select(laboratoryResults.length + laboratoryNameResults.length)"
        [ngClass]="{
          active: laboratoryResults.length + laboratoryNameResults.length === activeLaboratoryIndex,
          disabled: isDomestic(subcontracts[subcontractIndex])
        }"
      >
        <i class="pi pi-plus-circle"></i>
        <span translate="subcontract.addNewForeignSubcontract" [translateParams]="{term: searchTerm}"></span>
      </div>
      <p
        *ngIf="isDomestic(subcontracts[subcontractIndex])"
        translate="subcontract.cannotChangeDomesticLaboratory"></p>
    </div>
  </div>
</div>
