/* src/app/login/login.component.scss */
h1 {
  margin-top: 66px;
  margin-bottom: 70px;
  text-align: center;
}
.box {
  margin-top: 30px;
  padding: 24px;
}
.buttons {
  text-align: center;
}
a {
  display: block;
  margin: 34px 0;
}
img {
  width: 205px;
}
/*# sourceMappingURL=login.component.css.map */
