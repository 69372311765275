/* src/app/application/component/application-researches/application-researches-subcontract-panels.component.scss */
.panel .panel-header {
  padding: 7px 25px 6px 25px;
}
.panel .panel-header h3 {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-weight: 600;
}
.panel .panel-header h3 > *:first-child {
  margin-right: 5px;
}
.panel .panel-header h3 .input {
  flex-grow: 1;
}
.panel .panel-header h3 .input input {
  font-weight: 600;
  width: 100%;
  max-width: 425px;
  background-color: #F2F2F2;
}
.panel .panel-header h3 .input input:focus {
  background-color: rgba(255, 255, 255, 0.75);
}
.panel .panel-header h3 .input input:placeholder-shown {
  font-style: italic;
  background-color: rgba(255, 255, 255, 0.85);
}
.panel .panel-header h3 > *:last-child {
  margin-left: 5px;
  color: #606060;
  font-weight: normal;
}
.subcontract-dropdown {
  width: 546px;
}
.subcontract-dropdown .group {
  border-bottom: 1px solid #DCDFE2;
}
.subcontract-dropdown .group h4 {
  font-weight: bold;
  text-transform: uppercase;
  margin: 25px 12px 0 12px;
}
.subcontract-dropdown .group ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.subcontract-dropdown .group ul li {
  padding: 18px 12px;
  cursor: pointer;
}
.subcontract-dropdown .group ul li.active {
  background-color: #EDF0F2;
}
.subcontract-dropdown .group p {
  margin: 15px 12px;
  color: rgba(0, 0, 0, 0.6);
}
.subcontract-dropdown .add-new-term {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 13px 13px 13px 0;
}
.subcontract-dropdown .add-new-term i {
  font-size: 30px;
  margin: 0 16px;
}
.subcontract-dropdown .add-new-term.active {
  background-color: #EDF0F2;
}
/*# sourceMappingURL=application-researches-subcontract-panels.component.css.map */
