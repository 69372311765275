/* src/app/app.component.scss */
.top {
  display: flex;
  align-items: center;
  height: 64px;
  padding: 0 16px;
  background-color: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}
.top .tolu-text {
  margin-right: 12px;
}
.top .tolu-text .first {
  display: block;
  font-size: 14px;
  font-weight: 600;
}
.top .tolu-text .second {
  font-size: 28px;
  font-weight: bold;
}
.top img {
  width: 205px;
}
.top .menu {
  margin-left: auto;
}
.top .menu a,
.top .menu select {
  margin-left: 2em;
  color: #2F62AD;
  font-weight: 600;
  text-decoration: none;
}
@media print {
  .top {
    display: none;
  }
}
/*# sourceMappingURL=app.component.css.map */
