<form *ngIf="laboratory" [formGroup]="form" (ngSubmit)="save()">
  <h4 class="title" translate="laboratory.organisation"></h4>
  <div class="columns">
    <div>
      <div class="stacked-input">
        <label for="organisationName" translate="laboratory.organisationName"></label>
        <app-validation-error-message [control]="form.get('organisationName')"></app-validation-error-message>
        <input
          type="text"
          id="organisationName"
          [formControl]="form.get('organisationName')"
          required
        />
      </div>

      <div class="stacked-input">
        <label for="organisationAddress" translate="laboratory.organisationAddress"></label>
        <app-validation-error-message [control]="form.get('organisationAddress')"></app-validation-error-message>
        <input
          type="text"
          id="organisationAddress"
          [formControl]="form.get('organisationAddress')"
          required
        />
      </div>

      <div class="columns">
        <div class="stacked-input">
          <label for="organisationPostalCode" translate="laboratory.organisationPostalCode"></label>
          <app-validation-error-message [control]="form.get('organisationPostalCode')"></app-validation-error-message>
          <input
            type="text"
            id="organisationPostalCode"
            [formControl]="form.get('organisationPostalCode')"
            required
          />
        </div>

        <div class="stacked-input">
          <label for="organisationCity" translate="laboratory.organisationCity"></label>
          <app-validation-error-message [control]="form.get('organisationCity')"></app-validation-error-message>
          <input
            type="text"
            id="organisationCity"
            [formControl]="form.get('organisationCity')"
            required
          />
        </div>
      </div>
    </div>
    <div>
      <div class="stacked-input">
        <label for="businessIdentityCode" translate="laboratory.businessIdentityCode"></label>
        <app-validation-error-message [control]="form.get('businessIdentityCode')"></app-validation-error-message>
        <input
          type="text"
          id="businessIdentityCode"
          [formControl]="form.get('businessIdentityCode')"
        />
      </div>
      <div class="stacked-input">
        <label for="billingContact" translate="laboratory.billingContact"></label>
        <app-validation-error-message [control]="form.get('billingContact')"></app-validation-error-message>
        <input
          type="text"
          id="billingContact"
          [formControl]="form.get('billingContact')"
        />
      </div>
    </div>
  </div>
  <h4 class="title" translate="laboratory.laboratory"></h4>
  <div class="columns">
    <div>
      <div class="stacked-input">
        <label for="name" translate="laboratory.name"></label>
        <app-validation-error-message [control]="form.get('name')"></app-validation-error-message>
        <input
          type="text"
          id="name"
          [formControl]="form.get('name')"
          required
        />
      </div>

      <div class="stacked-input">
        <label for="address" translate="laboratory.address"></label>
        <app-validation-error-message [control]="form.get('address')"></app-validation-error-message>
        <input
          type="text"
          id="address"
          [formControl]="form.get('address')"
          required
        />
      </div>

      <div class="columns">
        <div>
          <div class="stacked-input">
            <label for="postalCode" translate="laboratory.postalCode"></label>
            <app-validation-error-message [control]="form.get('postalCode')"></app-validation-error-message>
            <input
              type="text"
              id="postalCode"
              [formControl]="form.get('postalCode')"
              required
            />
          </div>

          <div class="stacked-input">
            <label for="phoneNumber" translate="laboratory.phoneNumber"></label>
            <app-validation-error-message [control]="form.get('phoneNumber')"></app-validation-error-message>
            <input
              type="text"
              id="phoneNumber"
              [formControl]="form.get('phoneNumber')"
              required
            />
          </div>
        </div>

        <div class="stacked-input">
          <label for="city" translate="laboratory.city"></label>
          <app-validation-error-message [control]="form.get('city')"></app-validation-error-message>
          <input
            type="text"
            id="city"
            [formControl]="form.get('city')"
            required
          />
        </div>
      </div>
    </div>
    <div>
      <div class="stacked-input">
        <label>&nbsp;</label>
        <div>
          <label class="checkbox">
            <input type="checkbox" id="terminated" [formControl]="form.get('terminated')" />
            {{ 'laboratory.terminated' | translate }}
          </label>
        </div>
      </div>
    </div>
  </div>
  <a *ngIf="form.disabled" translate="laboratory.modifyBasicInfo" (click)="onEditClick()"></a>
  <div *ngIf="form.enabled" class="buttons">
    <button type="submit" [disabled]="form.invalid" translate="save"></button>
    <a (click)="cancel()" translate="cancel"></a>
  </div>
</form>
