import {Entity} from './model/entity';
import {UntypedFormGroup} from '@angular/forms';
import * as _ from 'lodash';
import {SaveResponse} from './model/save-response';

export class Util {
  static entityPropertiesToForm(entity: Entity<any, any>, form: UntypedFormGroup) {
    if (! entity) {
      return;
    }

    _.each(form.controls, (control, name) => control.setValue(entity.properties[name]));
  }

  static formToEntityProperties(form: UntypedFormGroup, entity: Entity<any, any>) {
    _.each(form.controls, (control, name) => {
      entity.properties[name] = control.value;
    });
  }

  static errorsToForm(response: SaveResponse<any, any>, form: UntypedFormGroup) {
    _.each(response.validation.messages, (messages, property) => {
      if (messages.length === 0) {
        return;
      }

      const errors = messages.reduce((curErrors, message) => {
        curErrors[message] = true;
        return curErrors;
      }, {});

      const control = form.get(property);
      if (control) {
        control.setErrors(errors);
      }
    });
  }
}
