<div id="searchable-dropdown">
    <label *ngIf="label" for="my-input">{{label | translate}}</label>
    <input id="my-input" type="text" autocomplete="off" [(ngModel)]="inputValue" (input)="filterOptions()" (focus)="onFocus()" (blur)="onBlur()"/>
    <ul *ngIf="dropdownVisible">
      <li *ngFor="let item of filteredItems" (mousedown)="selectOption(item)">
        <span *ngFor="let field of searchFields; let last = last">
          {{ item.properties[field] }}
          <span *ngIf="!last">, </span>
        </span>
      </li>
    </ul>
</div>