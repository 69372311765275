<table [ngClass]="{narrow: ! showRedundantFields}">
  <thead>
    <tr class="first-row">
      <th translate="research.number"></th>
      <th translate="research.abbreviation"></th>
      <th translate="research.name"></th>
      <th translate="research.amount"></th>
      <ng-container *ngIf="showRedundantFields">
        <th translate="research.externalQualityControl" colspan="3"></th>
        <th translate="research.accredited"></th>
      </ng-container>
      <th></th>
    </tr>
    <tr class="size-12 second-row" *ngIf="showRedundantFields">
      <th colspan="4"></th>
      <th translate="research.labQuality"></th>
      <th translate="research.ukneqas"></th>
      <th translate="research.qcmd"></th>
      <th colspan="2"></th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngFor="let group of formGroups(); let index = index">
      <tr *ngIf="group.invalid && group.touched" class="validation-row">
        <td>
          <app-validation-error-message
            [control]="group.get('number')"
          ></app-validation-error-message>
        </td>
        <td>
          <app-validation-error-message
            [control]="group.get('abbreviation')"
          ></app-validation-error-message>
        </td>
        <td>
          <app-validation-error-message
            [control]="group.get('name')"
          ></app-validation-error-message>
        </td>
        <td>
          <app-validation-error-message
            [control]="group.get('amount')"
          ></app-validation-error-message>
        </td>
        <ng-container *ngIf="showRedundantFields">
          <td>
            <app-validation-error-message
              [control]="group.get('qualityControl')"
            ></app-validation-error-message>
          </td>
          <td>
            <app-validation-error-message
              [control]="group.get('labQuality')"
            ></app-validation-error-message>
          </td>
          <td>
            <app-validation-error-message
              [control]="group.get('ukneqas')"
            ></app-validation-error-message>
          </td>
          <td>
            <app-validation-error-message
              [control]="group.get('qcmd')"
            ></app-validation-error-message>
          </td>
          <td>
            <app-validation-error-message
              [control]="group.get('accredited')"
            ></app-validation-error-message>
          </td>
        </ng-container>
      </tr>
      <tr class="input-row">
        <td class="number">
          <input
            type="text"
            class="inline"
            [formControl]="group.get('number')"
            [appDropdownInput]="researchDropdownMenu"
            [length]="researchResults.length + 1"
            [(active)]="activeResearchIndex"
            (selection)="select($event, group, amountInput)"
            (focusin)="numberSearchTerms.next(numberInput.value)"
            (input)="numberSearchTerms.next(numberInput.value)"
            #numberInput
          />
        </td>
        <td class="abbreviation">
          <input
            type="text"
            class="inline"
            [formControl]="group.get('abbreviation')"
            [appDropdownInput]="researchDropdownMenu"
            [length]="researchResults.length + 1"
            [(active)]="activeResearchIndex"
            (selection)="select($event, group, amountInput)"
            (focusin)="abbreviationSearchTerms.next(abbreviationInput.value)"
            (input)="abbreviationSearchTerms.next(abbreviationInput.value)"
            #abbreviationInput
          />
        </td>
        <td class="name">
          <input
            type="text"
            class="inline"
            [formControl]="group.get('name')"
            [appDropdownInput]="researchDropdownMenu"
            [length]="researchResults.length + 1"
            [(active)]="activeResearchIndex"
            (selection)="select($event, group, amountInput)"
            (focusin)="nameSearchTerms.next(nameInput.value)"
            (input)="nameSearchTerms.next(nameInput.value)"
            #nameInput
          />
        </td>
        <td class="amount">
          <input #amountInput type="number" class="inline" [formControl]="group.get('amount')" />
        </td>
        <ng-container *ngIf="showRedundantFields">
          <td class="labQuality">
            <input type="number" class="inline" [formControl]="group.get('labQuality')" />
          </td>
          <td class="ukneqas">
            <input type="number" class="inline" [formControl]="group.get('ukneqas')" />
          </td>
          <td class="qcmd">
            <input type="number" class="inline" [formControl]="group.get('qcmd')" />
          </td>
          <td class="accredited">
            <input type="checkbox" [formControl]="group.get('accredited')" />
          </td>
        </ng-container>
        <td class="remove" (click)="remove(index)">
          &times;
        </td>
      </tr>
    </ng-container>
  </tbody>
</table>

<div class="actions">
  <span (click)="addNewResearch()" class="button size-16">
    <i class="pi pi-plus-circle"></i>
    <span translate="research.addNewResearch"></span>
  </span>

  <span (click)="fileInput.click(); fileInput.value = ''" class="button size-16">
    <i class="pi pi-upload"></i>
    <span translate="research.importFile"></span>
    <input #fileInput (change)="importExcel($event)" type="file" accept=".xlsx" />
  </span>

  <span *ngIf="application" (click)="copyResearches()" class="button size-16">
    <i class="pi pi-copy"></i>
    <span translate="research.copyResearches"></span>
  </span>
</div>

<div
  class="dropdown-menu research-dropdown"
  appDropdownMenu
  #researchDropdownMenu="dropdownMenuDirective"
>
  <table>
    <tbody>
      <tr
        *ngFor="let research of researchResults; let index = index"
        (mouseover)="activeResearchIndex = index"
        (click)="researchDropdownMenu.select(index)"
        [ngClass]="{
          active: index === activeResearchIndex
        }"
      >
        <td>{{ research.number }}</td>
        <td>{{ research.abbreviation }}</td>
        <td>{{ research.name }}</td>
      </tr>
    </tbody>
  </table>
  <div
    class="add-new-term size-16"
    (mouseover)="activeResearchIndex = researchResults.length"
    [ngClass]="{
      active: researchResults.length === activeResearchIndex
    }"
  >
    <i class="pi pi-plus-circle"></i>
    <span translate="research.useNewTermHere" [translateParams]="{term: searchTerm}"></span>
  </div>
</div>
