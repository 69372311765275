<div class="page">
  <div class="page-content">
    <div class="header">
      <h1 translate="laboratorySearch.title"></h1>
    </div>
    <div class="box">
      <div class="searchFormContainer">
        <div *ngIf="avis" class="filterElement" id="avis">
          <label for="avi" translate="laboratorySearch.searchForm.avi"></label>
          <select id="avi" [(ngModel)]="avi">
            <option [value]="-1" translate="all"></option>
            <option *ngFor="let avi of avis" [value]="avi.properties.id">{{ avi.properties.name }}</option>
          </select>
        </div>
        <div class="filterElement">
          <label for="nameFilter" translate="laboratorySearch.searchForm.name"></label>
          <input id="nameFilter" type="text" [(ngModel)]="laboratoryNameFilter" />
        </div>
        <br />
        <div class="filterElement">
          <label for="researchNumberFilter" translate="laboratorySearch.searchForm.researchNumber"></label>
          <input id="researchNumberFilter" type="text" [(ngModel)]="researchNumberFilter" />
        </div>
        <div *ngIf="laboratory_categories" class="filterElement" id="laboratory_categories">
          <label for="laboratory_category" translate="laboratorySearch.searchForm.labCategory"></label>
          <select id="laboratory_category" [(ngModel)]="selectedLaboratoryCategoryId">
            <option [value]="-1" translate="all"></option>
            <option *ngFor="let laboratory_category of laboratory_categories" [value]="laboratory_category.properties.id">{{ laboratory_category.properties.descriptionFi }}</option>
          </select>
        </div>
        <app-searchable-drop-down class="filterElement foobar" [items]="researchTemplates" [searchFields]="['name']" [keyField]="'number'" [label]="'laboratorySearch.searchForm.research'" (selectEvent)="selectResearch($event)"></app-searchable-drop-down>
        <label class="checkbox filterElement">
          <input type="checkbox"
                 id="showTerminated"
                 [ngModel]="showTerminated"
                 (change)="searchFormUpdated({ showTerminated: !this.showTerminated })" />
          {{ 'laboratorySearch.searchForm.showTerminated' | translate }}
        </label>
        <label class="checkbox filterElement">
          <input type="checkbox"
            id="isSupervisingLaboratoryFilter"
            [(ngModel)]="isSupervisingLaboratoryFilter" />
          {{ 'laboratorySearch.searchForm.isSupervisingLaboratory' | translate }}
        </label>
      </div>
      <table>
        <thead>
          <tr>
            <th>
              <a routerLink="." [queryParams]="buildQueryParams({ orderByKey: 'referenceNumber', orderByDir: (orderByKey === 'referenceNumber' && orderByDir === 'ASC') ? 'DESC' : 'ASC' })">
                <span translate="laboratorySearch.columns.referenceNumber"></span>
                <i class="pi pi-sort{{ orderByKey === 'referenceNumber' ? (orderByDir === 'ASC' ? '-up' : '-down') : '' }}"></i>
              </a>
            </th>
            <th>
              <a routerLink="." [queryParams]="buildQueryParams({ orderByKey: 'name', orderByDir: (orderByKey === 'name' && orderByDir === 'ASC') ? 'DESC' : 'ASC' })">
                <span translate="laboratorySearch.columns.laboratoryName"></span>
                <i class="pi pi-sort{{ orderByKey === 'name' ? (orderByDir === 'ASC' ? '-up' : '-down') : '' }}"></i>
              </a>
            </th>
            <th translate="laboratorySearch.columns.supervisedLabCount"></th>
            <th translate="laboratorySearch.columns.status"></th>
            <th>
              <a routerLink="." [queryParams]="buildQueryParams({ orderByKey: 'latestApplication.validUntilDate', orderByDir: (orderByKey === 'latestApplication.validUntilDate' && orderByDir === 'ASC') ? 'DESC' : 'ASC' })">
                <span translate="laboratorySearch.columns.validUntil"></span>
                <i class="pi pi-sort{{ orderByKey === 'latestApplication.validUntilDate' ? (orderByDir === 'ASC' ? '-up' : '-down') : '' }}"></i>
              </a>
            </th>
            <th translate="laboratorySearch.columns.numberOfResearchesPerYear"></th>
            <th translate="laboratorySearch.columns.totalNumberOfResearchesPerYear"></th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let lab of labs | async">
            <tr *ngIf="getFilter(lab)">
              <td>{{ lab.properties.referenceNumber }}</td>
              <td>
                <a [routerLink]="['/laboratory', lab.properties.id]">{{ lab.properties.name }}</a>
              </td>
              <td>
                <span *ngFor="let supervisedLab of getSupervisedLabCount(lab); let i = index">
                  <a [routerLink]="['/laboratory', supervisedLab.id]" [pTooltip]="supervisedLab.name">{{ supervisedLab.referenceNumber }}</a>
                  <span *ngIf="i !== getSupervisedLabCount(lab).length - 1">, </span>
                </span>
              </td>
              <td translate="laboratorySearch.status.{{ getStatus(lab) }}"></td>
              <ng-container *ngIf="lab.with.latestApplication; else noApplication">
                <td>
                  <ng-container *ngIf="lab.with.latestApplication.properties.validForNow; else validUntil">
                    <span translate="laboratorySearch.validForNow"></span>
                  </ng-container>
                  <ng-template #validUntil>
                    {{ (lab.with.latestApplication.properties.validUntil | date:'dd.MM.yyyy') || '–' }}
                  </ng-template>
                </td>
                <td>{{ getResearchAmount(lab) || '–' }}</td>
                <td>{{ lab.with.latestApplication.with.totalNumberOfResearchesPerYear || '–' }}</td>
              </ng-container>
              <ng-template #noApplication>
                <td>–</td>
                <td>–</td>
                <td>–</td>
              </ng-template>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>
</div>
