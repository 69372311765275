<ng-container *ngIf="application">
  <div class="box-bordered">
    <div class="page">
      <div class="page-content">
        <h1
          *ngIf="application.properties.category === 1"
          translate="application.extensiveClinicalMicrobiologyLicenseApplicationForLaboratory"
        ></h1>
        <h1
          *ngIf="application.properties.category === 2"
          translate="application.condensedClinicalMicrobiologyLicenseApplicationForLaboratory"
        ></h1>
        <h1
          *ngIf="application.properties.category === 3"
          translate="application.noticeOfSupportiveUnitOfUrgentPatienceCare"
        ></h1>
        <div class="columns">
          <div>
            <span class="size-21 semi-bold">{{ application.with.laboratory.properties.name }}</span>
            <span class="size-18">{{ application.with.laboratory.properties.organisationName }}</span>
            <span class="category">
            <span class="semi-bold" translate="application.categoryOfApplication"></span>:
            <span class="circle">{{ application.properties.category }}</span>
          </span>
            <span>
            <span class="semi-bold" translate="application.initDate"></span>:
              {{ application.properties.initDate | date }}
          </span>
            <span>
            <span class="semi-bold" translate="application.arrivalDate"></span>:
              {{ application.properties.arrivalDate | date }}
          </span>
          </div>
          <div>
            <span class="semi-bold"><span translate="application.applicationDiaryNumberAvi"></span>:</span>
            <span>{{ application.properties.aviDiary }}</span>
            <span class="semi-bold"><span translate="application.issueNumberTHL"></span>:</span>
            <span>TODO</span>
            <span>&nbsp;</span>
            <span>
            <button class="secondary small" translate="application.modifyBasicInfo" (click)="toBasics()"></button>
          </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="page">
    <div class="buttons page-content">
      <div>
        <a
          [routerLink]="['/application', application.properties.id, 'contacts']"
          routerLinkActive="selected"
          class="button secondary huge"
          translate="application.contactInfo"
        ></a>
      </div>
      <div>
        <a
          [routerLink]="['/application', application.properties.id, 'researches']"
          routerLinkActive="selected"
          class="button secondary huge"
          translate="application.researchCatalog"
        ></a>
      </div>
    </div>
  </div>
</ng-container>
