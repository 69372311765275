<div class="page" *ngIf="application">
  <div class="page-content">
    <div class="back">
      <span class="size-21 semi-bold">&lsaquo;</span>
      &nbsp;
      <a
        [routerLink]="['/laboratory', application.with.laboratory.properties.id]"
        translate="laboratory.backToLaboratoryProfile"
      ></a>
    </div>
    <h1>{{ application.with.laboratory.properties.name }}</h1>
    <div class="narrow-content">
      <div class="h2-wrapper">
        <h2 translate="application.registerExtractAndStatement"></h2>
        <div class="diary">
          <div translate="application.applicationDiaryNumberAvi"></div>
          <span class="semi-bold">{{ application.properties.aviDiary }}</span>
        </div>
      </div>
      <div class="boxes">
        <div class="box">
          <div class="upper-content">
            <h2 translate="registerExtract.extractRegisterForStatement"></h2>
          </div>
          <div class="lower-content">
            <a
              [routerLink]="['/application', application.properties.id, 'register-extract']"
              class="button"
              translate="registerExtract.extractRegister"
            ></a>
            <label class="checkbox">
              <input type="checkbox" id="registerExtractedForStatement" [formControl]="form.get('registerExtracted')" />
              {{ 'registerExtract.registerExtractedForStatement' | translate }}
              {{ statementService.buildThlIdentifier(application.with.statement) }}
            </label>
          </div>
        </div>
        <div *ngIf="application.properties.category != 3;else disabled_info" class="box">
          <div class="upper-content">
            <h2 translate="statement.produceStatement"></h2>
            <p translate="statement.produceStatementInfo"></p>
          </div>
          <div class="lower-content">
            <a
              [routerLink]="['/application', application.properties.id, 'request-for-statement']"
              class="button"
              translate="statement.produceStatement"
            ></a>
            <label class="checkbox">
              <input type="checkbox" id="statementProduced" [formControl]="form.get('statementProduced')" />
              {{ 'statement.statementProduced' | translate }}
            </label>
          </div>
        </div>
        <ng-template #disabled_info>
          <div class="box">
            <div class="upper-content">
              <h2 translate="statement.produceStatement"></h2>
              <p translate="statement.produceStatementDisabledInfo"></p>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
