import {Injectable} from '@angular/core';
import {Service} from '../../base/service/service';
import { HttpClient } from '@angular/common/http';
import {LaboratoryCategory} from '../model/laboratory-category';

@Injectable({
  providedIn: 'root'
})
export class LaboratoryCategoryService extends Service<LaboratoryCategory> {
  constructor(
    public http: HttpClient
  ) {
    super(
      'api/laboratoryCategory',
      http
    );
  }
}
