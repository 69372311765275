/* src/app/register-extract/register-extract.component.scss */
#register-extract-page-content {
  width: 297mm;
  margin: auto;
}
#register-extract-page-content .register-extract-created-notification {
  margin-top: 10px;
}
#register-extract-page-content .buttons-for-register-extract {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0 10px 0;
}
#register-extract-page-content .buttons-for-register-extract .print-button {
  margin-right: 20px;
}
#register-extract-page-content .buttons-for-register-extract .exit-link {
  text-decoration: underline;
  font-family: "Source Sans Pro";
  font-size: 14px;
  color: #2F62AD;
}
#register-extract-page-content .register-extract-doc-content {
  position: relative;
  width: 297mm;
  min-height: 210mm;
  background-color: white;
  padding: 1.7cm 1.4cm 1.7cm 1.4cm;
}
#register-extract-page-content .register-extract-doc-content .doc-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#register-extract-page-content .register-extract-doc-content .doc-header .logo {
  width: 45%;
  margin-left: -20px;
}
#register-extract-page-content .register-extract-doc-content .doc-header > .doc-title {
  margin: -5px 30px 0 0px;
  width: 10cm;
  font-size: 20px;
  font-weight: 600;
  line-height: 25px;
}
#register-extract-page-content .register-extract-doc-content .doc-header .register-extract-security-classification {
  font-weight: 400;
  margin-top: 10px;
  color: #DC3545;
  text-align: center;
  font-weight: 800;
}
#register-extract-page-content .register-extract-doc-content #register-extract-basic-data {
  display: flex;
}
#register-extract-page-content .register-extract-doc-content #register-extract-basic-data > div:first-child {
  width: 55%;
}
#register-extract-page-content .register-extract-doc-content #register-extract-basic-data > div:last-child {
  width: 45%;
  padding-top: 40px;
}
#register-extract-page-content .register-extract-doc-content #register-extract-basic-data .lab-name,
#register-extract-page-content .register-extract-doc-content #register-extract-basic-data .contact-information-title,
#register-extract-page-content .register-extract-doc-content #register-extract-basic-data .persons-in-charge-title {
  font-family: "Source Sans Pro";
  font-size: 20px;
  font-weight: bold;
}
#register-extract-page-content .register-extract-doc-content #register-extract-basic-data .lab-name {
  margin: 15px 0 20px 0;
}
#register-extract-page-content .register-extract-doc-content #register-extract-basic-data .contact-information-title,
#register-extract-page-content .register-extract-doc-content #register-extract-basic-data .persons-in-charge-title {
  margin: 15px 0 10px 0;
}
#register-extract-page-content .register-extract-doc-content #register-extract-basic-data .licence-basic-data > div,
#register-extract-page-content .register-extract-doc-content #register-extract-basic-data .contact-information > div,
#register-extract-page-content .register-extract-doc-content #register-extract-basic-data .persons-in-charge > div {
  display: flex;
  line-height: 23px;
}
#register-extract-page-content .register-extract-doc-content #register-extract-basic-data .licence-basic-data > div > span:first-child,
#register-extract-page-content .register-extract-doc-content #register-extract-basic-data .contact-information > div > span:first-child,
#register-extract-page-content .register-extract-doc-content #register-extract-basic-data .persons-in-charge > div > span:first-child {
  font-weight: 600;
}
#register-extract-page-content .register-extract-doc-content #register-extract-basic-data .licence-basic-data > div > span:first-child,
#register-extract-page-content .register-extract-doc-content #register-extract-basic-data .contact-information > div > span:first-child {
  width: 50%;
}
#register-extract-page-content .register-extract-doc-content #register-extract-basic-data .licence-basic-data > div > span:last-child,
#register-extract-page-content .register-extract-doc-content #register-extract-basic-data .contact-information > div > span:last-child {
  width: 50%;
}
#register-extract-page-content .register-extract-doc-content #register-extract-basic-data .persons-in-charge > div > span:first-child {
  width: 55%;
}
#register-extract-page-content .register-extract-doc-content #register-extract-basic-data .persons-in-charge > div > span:last-child {
  width: 45%;
  padding-left: 15px;
}
#register-extract-page-content .register-extract-doc-content .supervised-labs-title {
  font-family: "Source Sans Pro";
  font-size: 20px;
  font-weight: bold;
  margin: 20px 0 10px 0;
}
#register-extract-page-content .register-extract-doc-content .supervised-labs {
  columns: 2;
}
#register-extract-page-content .register-extract-doc-content .supervised-labs > div {
  height: 40px;
  display: flex;
  align-items: center;
}
#register-extract-page-content .register-extract-doc-content .supervised-labs > div > span:first-child {
  flex: 10%;
  font-size: 14px;
}
#register-extract-page-content .register-extract-doc-content .supervised-labs > div > span:last-child {
  flex: 90%;
  font-size: 14px;
}
#register-extract-page-content .register-extract-doc-content .header-for-researches {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#register-extract-page-content .register-extract-doc-content .header-for-researches .logo {
  width: 45%;
  margin-left: -20px;
}
#register-extract-page-content .register-extract-doc-content .header-for-researches .lab-name {
  font-family: "Source Sans Pro";
  font-size: 21px;
  font-weight: bold;
  color: black;
  line-height: 28px;
}
#register-extract-page-content .register-extract-doc-content .header-for-researches .researches-title {
  font-family: "Source Sans Pro";
  font-size: 18px;
}
#register-extract-page-content .register-extract-doc-content .header-for-researches .researches-title-and-date {
  display: flex;
  justify-content: space-between;
}
#register-extract-page-content .register-extract-doc-content .researches .researches-title {
  margin: 30px 0 20px 0;
  color: #000000;
  font-family: "Source Sans Pro";
  font-size: 16px;
  font-weight: bold;
}
#register-extract-page-content .register-extract-doc-content .researches table {
  border-collapse: collapse;
  width: 100%;
}
#register-extract-page-content .register-extract-doc-content .researches table tr.top-th-cells > th {
  font-family: "Source Sans Pro";
  font-size: 14px;
  line-height: 13px;
  text-align: left;
  color: #303030;
}
#register-extract-page-content .register-extract-doc-content .researches table tr.bottom-th-cells > th {
  font-family: "Source Sans Pro";
  font-size: 12px;
  font-weight: normal;
  line-height: 13px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}
#register-extract-page-content .register-extract-doc-content .researches table td {
  color: #303030;
  font-family: "Source Sans Pro";
  font-size: 14px;
  border-bottom: 1px solid #ddd;
}
#register-extract-page-content .register-extract-doc-content .researches table tr.top-th-cells > th.amount,
#register-extract-page-content .register-extract-doc-content .researches table td.amount {
  text-align: right;
  padding-right: 30px;
}
#register-extract-page-content .register-extract-doc-content .researches table tbody > tr {
  height: 43px;
}
#register-extract-page-content .register-extract-doc-content .researches .explain {
  text-align: right;
  font-size: 10px;
  font-weight: normal;
}
.thl-contact-info-for-print {
  display: none;
}
@media print {
  .register-extract-header,
  .register-extract-footer {
    display: none;
  }
  .register-extract-doc-content {
    padding: 0 !important;
  }
  .header-for-researches {
    page-break-before: always;
    break-before: always;
  }
  .thl-contact-info-for-print {
    display: block;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .thl-contact-info-for-print > div {
    text-align: center;
  }
  .thl-contact-info-for-print .thl-name {
    font-size: 12px;
    font-weight: 600;
  }
  .thl-contact-info-for-print .thl-address {
    margin-top: -3px;
    font-size: 12px;
  }
  .thl-contact-info-for-print .thl-twitter {
    font-size: 14px;
    font-weight: 600;
    color: #7bc143;
  }
}
/*# sourceMappingURL=register-extract.component.css.map */
