/* src/app/laboratory/component/laboratory-search/laboratory-search.component.scss */
.header {
  margin-top: 30px;
}
.box {
  padding: 24px;
  margin-bottom: 24px;
}
.searchFormContainer {
  margin-bottom: 1em;
  padding-bottom: 1em;
  border-bottom: 1px solid #F2F2F2;
  display: flex;
  flex-wrap: wrap;
}
.searchFormContainer .filterElement {
  width: 25%;
  display: flex;
  flex-direction: column;
  padding-right: 50px;
  margin-top: 20px;
}
.searchFormContainer .filterElement input[type=text] {
  height: 24px;
  border-color: #000;
}
.searchFormContainer .checkbox {
  flex-direction: row;
}
table {
  width: 100%;
  border-spacing: 0;
}
th,
td {
  padding: 5px;
  border-bottom: 1px solid #F2F2F2;
  text-align: left;
}
th a {
  color: #212529;
}
th i {
  color: #949494;
}
th i.pi-sort-up,
th i.pi-sort-down {
  color: #000;
}
td a {
  color: #2F62AD;
  text-decoration: underline;
}
i.pi {
  font-size: 1em;
}
/*# sourceMappingURL=laboratory-search.component.css.map */
