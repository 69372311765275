import {Component, OnInit} from '@angular/core';
import {ApplicationFind} from '../../model/application-find';
import {ActivatedRoute} from '@angular/router';
import {ApplicationService} from '../../service/application.service';
import {Application} from '../../model/application';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {Util} from '../../../base/util';
import {forkJoin, of, throwError} from 'rxjs';
import {switchMap, tap} from 'rxjs/operators';
import {ResearchService} from '../../service/research.service';
import {MessageService} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import {SubcontractFindWith} from '../../../subcontract/model/subcontract-find-with';
import {LaboratoryService} from '../../../laboratory/service/laboratory.service';
import {SubcontractService} from '../../service/subcontract.service';
import {Research} from '../../model/research';
import {Subcontract} from '../../model/subcontract';
import {LaboratoryFindWith} from '../../../laboratory/model/laboratory-find-with';

@Component({
  templateUrl: './application-researches.component.html',
  styleUrls: ['./application-researches.component.scss']
})
export class ApplicationResearchesComponent implements OnInit {
  application: Application;

  form: UntypedFormGroup;
  removedResearches: Research[] = [];
  removedSubcontracts: Subcontract[] = [];

  constructor(
    private route: ActivatedRoute,
    private applicationService: ApplicationService,
    private researchService: ResearchService,
    private formBuilder: UntypedFormBuilder,
    private messageService: MessageService,
    private translateService: TranslateService,
    private laboratoryService: LaboratoryService,
    private subcontractService: SubcontractService
  ) {}

  ngOnInit() {
    const find = new ApplicationFind();
    find.with.laboratory = new LaboratoryFindWith();
    find.with.researches = true;

    find.with.subcontracts = new SubcontractFindWith();
    find.with.subcontracts.laboratory = true;
    find.with.subcontracts.researches = true;

    this.route.params.subscribe(params =>
      this.applicationService.findById(params.applicationId, find).subscribe(application => {
        this.application = application;

        this.buildForm();
      })
    );
  }

  buildForm() {
    this.form = this.formBuilder.group({
      researches: this.formBuilder.array([]),
      subcontracts: this.formBuilder.array([])
    });

    // TODO: Should not be needed: https://juristr.com/blog/2019/02/display-server-side-validation-errors-with-angular/
    this.form.valueChanges.subscribe(value => {
      this.form.setValue(value, {emitEvent: false});
    });
  }

  save() {
    this.deleteResearches().pipe(
      switchMap(succeed => {
        if (! succeed) {
          return throwError(null);
        }

        return this.deleteSubcontracts();
      }),
      switchMap(succeed => {
        if (! succeed) {
          return throwError(null);
        }

        return forkJoin([this.saveResearches(), this.saveSubcontracts()]);
      }),
      switchMap(responses => {
        if (responses.some(response => ! response.valid)) {
          return throwError(null);
        }

        const observables = this.saveAllSubcontractsResearches();
        if (observables.length === 0) {
          return of([]);
        }

        return forkJoin(observables);
      }),
      switchMap(responses => {
        if (responses.some(response => ! response.valid)) {
          return throwError(null);
        }

        return of(null);
      })
    ).subscribe(
      () => this.messageService.add({
        severity: 'success',
        summary: this.translateService.instant('saveSucceed')
      }),
      () => {
        this.form.markAllAsTouched();
        this.messageService.add({
          severity: 'error',
          summary: this.translateService.instant('saveFailed')
        });
      }
    );
  }

  deleteResearches() {
    const ids = this.removedResearches.map(research => research.properties.id);
    if (ids.length === 0) {
      return of(true);
    }

    return this.researchService.deleteAll(ids).pipe(
      tap(() => this.removedResearches = [])
    );
  }

  deleteSubcontracts() {
    const ids = this.removedSubcontracts.map(subcontract => subcontract.properties.id);
    if (ids.length === 0) {
      return of(true);
    }

    return this.subcontractService.deleteAll(ids).pipe(
      tap(() => this.removedSubcontracts = [])
    );
  }

  saveResearches() {
    this.application.with.researches.forEach((research, index) => {
      research.properties.applicationId = this.application.properties.id;

      const group = this.ownResearchesFormArray().at(index) as UntypedFormGroup;
      Util.formToEntityProperties(group, research);
    });

    const properties = this.application.with.researches.map(research => research.properties);

    return this.researchService.saveAll(properties).pipe(
      tap(allResponse => {
        if (! allResponse.valid) {
          allResponse.responses.forEach((response, index) => {
            Util.errorsToForm(response, this.ownResearchesFormArray().at(index) as UntypedFormGroup);
          });

          return;
        }

        this.application.with.researches
          .forEach((research, index) => research.properties = allResponse.responses[index].properties);
      })
    );
  }

  saveSubcontracts() {
    this.application.with.subcontracts.forEach((subcontract, index) => {
      subcontract.properties.applicationId = this.application.properties.id;

      const group = this.subcontractsFormArray().at(index) as UntypedFormGroup;
      Util.formToEntityProperties(group, subcontract);
    });

    const properties = this.application.with.subcontracts.map(subcontract => subcontract.properties);

    return this.subcontractService.saveAll(properties).pipe(
      tap(allResponse => {
        if (! allResponse.valid) {
          allResponse.responses.forEach((response, index) => {
            Util.errorsToForm(response, this.subcontractsFormArray().at(index) as UntypedFormGroup);
          });

          return;
        }

        this.application.with.subcontracts
          .forEach((subcontract, index) => subcontract.properties = allResponse.responses[index].properties);
      })
    );
  }

  saveAllSubcontractsResearches() {
    return this.application.with.subcontracts.map((subcontract, subcontractIndex) => {
      const researchesFormArray = this.subcontractResearchesFormArray(subcontractIndex);

      subcontract.with.researches.forEach((research, index) => {
        research.properties.subcontractId = subcontract.properties.id;

        const researchGroup = researchesFormArray.at(index) as UntypedFormGroup;
        Util.formToEntityProperties(researchGroup, research);
      });

      const properties = subcontract.with.researches.map(research => research.properties);

      return this.researchService.saveAll(properties).pipe(
        tap(allResponse => {
          if (! allResponse.valid) {
            allResponse.responses.forEach((response, index) => {
              Util.errorsToForm(response, researchesFormArray.at(index) as UntypedFormGroup);
            });

            return;
          }

          subcontract.with.researches
            .forEach((research, index) => research.properties = allResponse.responses[index].properties);
        })
      );
    });
  }

  ownResearchesFormArray() {
    return this.form.get('researches') as UntypedFormArray;
  }

  subcontractsFormArray() {
    return this.form.get('subcontracts') as UntypedFormArray;
  }

  subcontractResearchesFormArray(index: number) {
    return this.subcontractsFormArray().at(index).get('researches') as UntypedFormArray;
  }
}
