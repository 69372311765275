export class ContactProperties {
  id: number;
  applicationId: number;
  role: string;
  name: string;
  education: string;
  phone: string;
  email: string;
  deleted: boolean;
}
