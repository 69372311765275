/* src/app/application/component/application-produce/application-produce.component.scss */
.back {
  padding: 10px 0;
}
h1 {
  margin: 0;
}
.narrow-content {
  max-width: 600px;
}
.narrow-content .h2-wrapper {
  display: flex;
}
.narrow-content .h2-wrapper h2 {
  flex-grow: 1;
}
.narrow-content .h2-wrapper .diary {
  text-align: right;
}
.narrow-content .boxes {
  display: flex;
}
.narrow-content .boxes .box {
  display: flex;
  flex-direction: column;
  padding: 12px;
  width: 50%;
  min-height: 340px;
}
.narrow-content .boxes .box:first-child {
  margin-right: 12px;
}
.narrow-content .boxes .box .upper-content {
  flex-grow: 1;
}
.narrow-content .boxes .box .upper-content h2 {
  margin-top: 0;
}
.narrow-content .boxes .box .lower-content {
  display: flex;
  flex-direction: column;
  min-height: 120px;
}
.narrow-content .boxes .box .lower-content .button {
  width: 100%;
  margin-bottom: 12px;
}
.narrow-content .boxes .box .lower-content label {
  flex-grow: 1;
  justify-content: center;
}
/*# sourceMappingURL=application-produce.component.css.map */
