import { HttpClient } from '@angular/common/http';
import {map} from 'rxjs/operators';
import {Find} from '../model/find';
import { ApplicationResponse } from '../../internal-frontpage/internal-frontpage.component'; // Add this import statement

export class Service<TEntity> {
  constructor(
    public baseUrl: string,
    public http: HttpClient
  ) {}

  findById(id: number, find?: Find) {
    if (! find) {
      find = new Find();
    }

    find.where = {idIs: id};
    return this.findOne(find);
  }

  find(find: Find) {
    return this.http.post<TEntity[]>(this.baseUrl + '/find', find);
  }

  findWithCount(find: Find) {
    return this.http.post<ApplicationResponse>(this.baseUrl + '/findwithcount', find);
  }

  findOne(find: Find) {
    find.limit = 1;
    return this.find(find).pipe(
      map(entities => entities[0])
    );
  }
}
