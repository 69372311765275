import { Component, OnInit } from '@angular/core';
import { LaboratoryService } from '../../service/laboratory.service';
import { LaboratoryFind } from '../../model/laboratory-find';
import { Laboratory } from '../../model/laboratory';
import { Observable } from 'rxjs';
import { LaboratoryFindWith } from '../../model/laboratory-find-with';
import { ApplicationFindWith } from '../../../application/model/application-find-with';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import { AviService } from '../../../avi/service/avi.service';
import { Avi } from '../../../avi/model/avi';
import { Find } from '../../../base/model/find';
import { LaboratoryCategory } from '../../model/laboratory-category';
import { LaboratoryCategoryService } from '../../service/laboratoryCategory.service';
import { ResearchService } from '../../../application/service/research.service';
import { ResearchTemplate } from '../../../application/model/research-template';
import { ResearchTemplateFind } from '../../../application/model/research-template-find';

@Component({
  templateUrl: './laboratory-search.component.html',
  styleUrls: ['./laboratory-search.component.scss']
})
export class LaboratorySearchComponent implements OnInit {
  private laboratoryFind: LaboratoryFind;
  private labs: Observable<Laboratory[]>;
  avis: Avi[];
  laboratory_categories: LaboratoryCategory[];
  researchTemplates: ResearchTemplate[];


  private showTerminated = false;
  private orderByKey: 'referenceNumber' | 'name' | 'latestApplication.validUntilDate' = 'latestApplication.validUntilDate';
  private orderByDir: 'ASC' | 'DESC' = 'ASC';
  private avi: number = -1;
  private selectedLaboratoryCategoryId: number = -1;
  private selectedResearch: string = '-1';
  private laboratoryNameFilter: string = '';
  private researchNumberFilter: string = '';
  private isSupervisingLaboratoryFilter: boolean = false;

  constructor(
    private laboratoryService: LaboratoryService,
    private aviService: AviService,
    private laboratoryCategoryService: LaboratoryCategoryService,
    private researchService: ResearchService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.researchService.findResearchTemplate(new ResearchTemplateFind()).subscribe(reserachTemplates => {
      this.researchTemplates = reserachTemplates;
    });
    this.laboratoryCategoryService.find(new Find()).subscribe(laboratoryCategories => this.laboratory_categories = laboratoryCategories);
    this.aviService.find(new Find()).subscribe(avis => this.avis = avis);
    this.activatedRoute.queryParams.subscribe(params => {
      this.showTerminated = params.showTerminated === 'true';
      this.orderByKey = params.orderByKey ? params.orderByKey : 'latestApplication.validUntilDate';
      this.orderByDir = params.orderByDir ? params.orderByDir : 'ASC';
      this.refreshSearchResults();
    });
  }

  private refreshSearchResults() {
    this.labs = null;

    this.laboratoryFind = new LaboratoryFind();
    this.laboratoryFind.where.excludeTerminated = !this.showTerminated;
    this.laboratoryFind.orders = [{ key: this.orderByKey, dir: this.orderByDir }];

    this.laboratoryFind.with = new LaboratoryFindWith();
    this.laboratoryFind.with.latestApplication = new ApplicationFindWith();
    this.laboratoryFind.with.supervisedLaboratories = true;
    this.laboratoryFind.with.latestApplication.researches = true;
    this.laboratoryFind.with.latestApplication.totalNumberOfResearchesPerYear = true;
    this.laboratoryFind.with.validSupervisedApplications = new ApplicationFindWith();

    this.labs = this.laboratoryService.find(this.laboratoryFind);
  }

  searchFormUpdated(newParams: any) {
    this.router.navigate(['laboratory'], {
      queryParams: this.buildQueryParams(newParams)
    });
  }

  buildQueryParams(newParams: any) {
    return {
      orderByKey: this.orderByKey,
      orderByDir: this.orderByDir,
      showTerminated: this.showTerminated,
      ...newParams
    };
  }

  getStatus(lab: Laboratory) {
    if (lab.properties.terminated) {
      return 'terminated';
    } else if (lab.with.latestApplication) {
      if (lab.with.latestApplication.properties.validityGrantedDate) {
        if (lab.with.latestApplication.properties.validForNow
          || _.lte(new Date().toISOString(), lab.with.latestApplication.properties.validUntil)) {
          return 'valid';
        } else {
          return 'expired';
        }
      } else {
        return 'in_progress';
      }
    } else {
      return 'no_application';
    }
  }

  getResearchAmount(lab: Laboratory): number | undefined {
    return (this.selectedResearch != '-1' && lab.with.latestApplication)
      ? lab.with.latestApplication.with.researches.find(research => research.properties.number == this.selectedResearch)?.properties.amount
      : undefined;
  }

  getFilter(lab: Laboratory): boolean {
    const aviFilter: boolean = this.avi == -1 || (lab.with.latestApplication && lab.with.latestApplication.properties.aviId == this.avi);
    const labCategoryFilter: boolean = this.selectedLaboratoryCategoryId == -1 || (lab.with.latestApplication && lab.with.latestApplication.properties.category == this.selectedLaboratoryCategoryId);
    const researchFilter: boolean = this.selectedResearch == '-1' || (lab.with.latestApplication && lab.with.latestApplication.with.researches.filter(research => research.properties.number == this.selectedResearch).length > 0);
    const nameFilter: boolean = this.laboratoryNameFilter == '' || lab.properties.name.toLowerCase().includes(this.laboratoryNameFilter.toLowerCase());
    const researchNumberFilter: boolean = this.researchNumberFilter == '' || this.searchLabByResearchNumber(lab, this.researchNumberFilter);
    const supervisingLaboratoryFilter: boolean = !this.isSupervisingLaboratoryFilter || (lab.with.validSupervisedApplications && lab.with.validSupervisedApplications.length > 0);

    return aviFilter && labCategoryFilter && researchFilter && nameFilter && researchNumberFilter && supervisingLaboratoryFilter;
  }

  selectResearch(selectedResearch: any) {
    if (selectedResearch == '-1') {
      this.selectedResearch = '-1';
      return;
    }
    this.selectedResearch = selectedResearch?.properties['number'];
  }

  getSupervisedLabCount(lab: Laboratory) {
    if (lab.with.latestApplication?.properties.category == 2 ||
      lab.with.latestApplication?.properties.category == 3) {
      return [];
    }

    return lab.with.supervisedLaboratories;
  }

  searchLabByResearchNumber(lab: Laboratory, researchNumber: string): boolean {
    let result = false;
    lab.with.latestApplication?.with.researches.forEach(research => {
      if (research.properties.number.toLowerCase() == researchNumber) {
        result = true;
      }
    });
    return result;
  }
}
