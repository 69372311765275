import {Component, OnInit} from '@angular/core';
import {ApplicationService} from '../../service/application.service';
import {ActivatedRoute, Router} from '@angular/router';
import {switchMap} from 'rxjs/operators';
import {Application} from '../../model/application';
import {ApplicationFind} from '../../model/application-find';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Util} from '../../../base/util';
import {MessageService} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import {LaboratoryFindWith} from '../../../laboratory/model/laboratory-find-with';

@Component({
  templateUrl: './application-validity.component.html',
  styleUrls: ['./application-validity.component.scss']
})
export class ApplicationValidityComponent implements OnInit {
  private application: Application;
  private form: UntypedFormGroup;

  constructor(
    private applicationService: ApplicationService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private messageService: MessageService,
    private translateService: TranslateService,
    private router: Router
  ) {}

  ngOnInit() {
    this.activatedRoute.params.pipe(
      switchMap(params => {
        const find = new ApplicationFind();
        find.with.laboratory = new LaboratoryFindWith();

        return this.applicationService.findById(params.applicationId, find);
      })
    )
      .subscribe(application => {
        this.application = application;
        this.setupForm();
      });
  }

  setupForm() {
    this.form = this.formBuilder.group({
      validityGrantedDate: ['', Validators.required],
      validUntil: ['', this.validUntilValidator.bind(this)],
      validForNow: ['', Validators.required],
    });

    this.form.valueChanges.subscribe(value => {
      const validUntil = this.form.get('validUntil');

      validUntil.enable({emitEvent: false});
      if (value.validForNow) {
        validUntil.setValue(null, {emitEvent: false});
        validUntil.disable({emitEvent: false});
      }
    });

    Util.entityPropertiesToForm(this.application, this.form);
  }

  save() {
    Util.formToEntityProperties(this.form, this.application);

    this.applicationService.save(this.application.properties).subscribe(response => {
      if (! response.validation.valid) {
        this.messageService.add({
          severity: 'error',
          summary: this.translateService.instant('application.saveFailed')
        });

        Util.errorsToForm(response, this.form);

        return;
      }

      this.messageService.add({
        severity: 'success',
        summary: this.translateService.instant('application.saveSucceed')
      });

      this.router.navigate(['laboratory', this.application.with.laboratory.properties.id]);
    });
  }

  validUntilValidator() {
    if (! this.form) {
      return;
    }

    if (
      this.form.get('validForNow').value === false &&
      ! this.form.get('validUntil').value
    ) {
      return {required: true};
    }
  }
}
