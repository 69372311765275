/* src/app/statement/statement-signer/statement-signer.component.scss */
.signer {
  display: flex;
  justify-content: space-between;
}
.signer .buttons {
  display: flex;
  justify-content: flex-start;
  margin-top: 10px;
}
.signer .buttons .save-button {
  margin-right: 20px;
}
/*# sourceMappingURL=statement-signer.component.css.map */
