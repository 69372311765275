/* src/app/statement/request-for-statement.component.scss */
.page-content {
  max-width: 1100px;
  margin-top: 30px;
  background-color: white;
  padding: 76px 100px 20px 100px;
}
.create-statement-title {
  color: #212529;
  font-family: "Source Sans Pro";
  font-size: 28px;
  font-weight: 600;
}
.statement-date-title {
  margin-top: 49px;
  color: #606060;
  font-family: "Source Sans Pro";
  font-size: 16px;
  font-weight: 600;
}
.receiver-title {
  margin-top: 49px;
  color: #606060;
  font-family: "Source Sans Pro";
  font-size: 16px;
  font-weight: 600;
}
.laboratory-info-title {
  margin-top: 49px;
  color: #606060;
  font-family: "Source Sans Pro";
  font-size: 16px;
  font-weight: 600;
}
.statement-title {
  margin-top: 49px;
  color: #606060;
  font-family: "Source Sans Pro";
  font-size: 16px;
  font-weight: 600;
}
.signers-title {
  margin-top: 49px;
  color: #606060;
  font-family: "Source Sans Pro";
  font-size: 16px;
  font-weight: 600;
}
hr {
  box-sizing: border-box;
  height: 1px;
  border: 1px solid #C3C2C6;
}
.statement-creation a {
  text-decoration: underline;
  font-family: "Source Sans Pro";
  font-size: 14px;
  color: #2F62AD;
}
.laboratory .header {
  margin-bottom: 5px;
  color: #212529;
  font-family: "Source Sans Pro";
  font-size: 20px;
  font-weight: 600;
}
.signer-1st {
  margin-top: 21px;
}
.signer-2nd {
  margin-top: 16px;
}
select {
  height: 40px;
}
.laboratory,
.receiver {
  display: flex;
  justify-content: space-between;
}
button.edit-laboratory {
  margin-left: 60px;
}
.buttons {
  display: flex;
  justify-content: center;
  margin-top: 300px;
}
.exit {
  margin-top: 20px;
  text-align: center;
}
.exit > a {
  color: black;
}
/*# sourceMappingURL=request-for-statement.component.css.map */
