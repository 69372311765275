import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ContactRole } from '../../enum/contact-role';

@Component({
  selector: 'app-application-contact-form',
  templateUrl: './application-contact-form.component.html',
  styleUrls: ['./application-contact-form.component.scss']
})
export class ApplicationContactFormComponent implements OnInit {
  @Input()
  form: UntypedFormGroup;

  roles: string[];

  constructor() { }

  ngOnInit() {
    this.roles = [ContactRole.ChiefInChargeOfClinicalMicrobiologyBusiness, ContactRole.PersonInChargeOfDailyLaboratoryBusiness, ContactRole.OtherLaboratoryContact];
  }

}
