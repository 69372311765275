/* src/app/laboratory/component/laboratory-profile/laboratory-profile.component.scss */
h1 {
  padding-top: 40px;
}
.columns > * {
  width: 50%;
}
.box {
  padding: 24px;
  margin-bottom: 24px;
}
.license .header {
  display: flex;
}
.license .header h2 {
  margin-top: 0;
  flex-grow: 1;
}
.license .header .diary {
  text-align: right;
}
.license .step {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  font-weight: 600;
}
.license .step > .circle {
  position: relative;
  font-size: 21px;
  line-height: 16px;
  color: #212529;
  height: 48px;
  width: 48px;
  border: 1px solid #C3C2C6;
  border-radius: 24px;
  background-color: #FAFAFA;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.25);
}
.license .step > .circle > .circle-inner {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.license .step > .circle > .check-mark {
  display: none;
}
.license .step > .description {
  margin-left: 14px;
  flex-grow: 1;
  font-size: 18px;
  color: #212529;
}
.license .step.completed > .circle > .check-mark {
  display: inline;
  color: #7bc143;
  font-size: 36px;
  line-height: 23px;
  position: absolute;
  bottom: -3px;
  right: -8px;
}
.license .step.in-progress > .circle {
  background-color: #2F62AD;
  border-color: #255293;
  color: white;
}
.license .step.in-progress > .description {
  color: #2F62AD;
  text-decoration: underline;
}
/*# sourceMappingURL=laboratory-profile.component.css.map */
