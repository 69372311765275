<div class="page" *ngIf="application">
  <div class="page-content">
    <div class="back">
      <span class="size-21 semi-bold">&lsaquo;</span>
      &nbsp;
      <a
        [routerLink]="['/laboratory', application.with.laboratory.properties.id]"
        translate="laboratory.backToLaboratoryProfile"
      ></a>
    </div>
    <h1>{{ application.with.laboratory.properties.name }}</h1>
    <div class="box">
      <div class="h2-wrapper">
        <h2 translate="application.markApplicationAsValid"></h2>
        <div class="diary">
          <div translate="application.applicationDiaryNumberAvi"></div>
          <span class="semi-bold">{{ application.properties.aviDiary }}</span>
        </div>
      </div>
      <form (ngSubmit)="save()" [formGroup]="form">
        <div class="stacked-input">
          <label for="validityGrantedDate" translate="application.validityGrantedDate"></label>
          <app-validation-error-message [control]="form.get('validityGrantedDate')"></app-validation-error-message>
          <input type="date" id="validityGrantedDate" [formControl]="form.get('validityGrantedDate')" required />
        </div>
        <div class="stacked-input">
          <label for="validUntil" translate="application.valid"></label>
          <div class="validity">
            <div>
              <app-validation-error-message [control]="form.get('validUntil')"></app-validation-error-message>
              <app-validation-error-message [control]="form.get('validForNow')"></app-validation-error-message>
              <input type="radio" [formControl]="form.get('validForNow')" [value]="false" />
              <input type="date" (focusin)="form.get('validForNow').setValue(false)" id="validUntil" [formControl]="form.get('validUntil')" />
              {{ 'application.until' | translate }}
            </div>
            <div>
              <label>
                <input type="radio" [formControl]="form.get('validForNow')" [value]="true" />
                {{ 'application.forNow' | translate }}
              </label>
            </div>
          </div>
        </div>
        <div class="footer">
          <div class="buttons">
            <button
              type="submit"
              [disabled]="form.invalid"
              translate="application.markAsValid"
            ></button>
          </div>
          <a
            [routerLink]="['/laboratory', application.with.laboratory.properties.id]"
            translate="cancel"
          ></a>
        </div>
      </form>
    </div>
  </div>
</div>
