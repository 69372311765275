<div [formGroup]="form">
    <div class="stacked-input">
        <label for="name" translate="contact.name"></label>
        <app-validation-error-message [control]="form.get('name')"></app-validation-error-message>
        <input type="text" id="name" [formControl]="form.get('name')" />
    </div>
    <div class="stacked-input">
        <label for="education" translate="contact.education"></label>
        <app-validation-error-message [control]="form.get('education')"></app-validation-error-message>
        <input type="text" id="education" formControlName="education" />
    </div>
    <div class="stacked-input">
        <label for="phone" translate="contact.phone"></label>
        <app-validation-error-message [control]="form.get('phone')"></app-validation-error-message>
        <input type="text" id="phone" formControlName="phone" />
    </div>
    <div class="stacked-input">
        <label for="email" translate="contact.email"></label>
        <app-validation-error-message [control]="form.get('email')"></app-validation-error-message>
        <input type="text" id="email" formControlName="email" />
    </div>
    <div class="stacked-input">
        <label for="role" translate="contact.role"></label>
        <app-validation-error-message [control]="form.get('role')"></app-validation-error-message>
        <select id="role" formControlName="role">
            <option *ngFor="let role of roles" [ngValue]="role">{{ 'contact.roles.' + role | translate }}</option>
        </select>
    </div>
</div>