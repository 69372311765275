<div *ngIf="statement" class="page-content statement-creation">
  <div class="create-statement-title">{{ 'statement.create-statement' | translate }}</div>
  <div class="statement-date-title">{{ 'statement.statementDate' | translate }}</div>
  <hr>
  <input type="date" id="statementDate" [ngModel]="statement.statementDate | date:'yyyy-MM-dd'" (ngModelChange)="statement.statementDate = $event" />
  <div class="receiver-title">{{ 'statement.receiver' | translate }}</div>
  <hr>
  <div class="receiver">
    <div>
      <div [innerHTML]="statement.aviContactInfo"></div>
    </div>
  </div>
  <div class="laboratory-info-title">{{ 'statement.laboratoryInfo' | translate }}</div>
  <hr>
  <div class="laboratory">
    <div>
      <div class="header">{{ 'statement.laboratoryName' | translate }}</div>
      <div>{{statement.laboratoryName}}</div>
    </div>
    <div>
      <div class="header">{{ 'statement.referenceNumberShort' | translate }}</div>
      <div>{{statement.laboratoryReferenceNumber}}</div>
    </div>
    <div>
      <div class="header">{{ 'statement.requestDateShort' | translate }}</div>
      <div>{{statement.requestDate  | dateonly}}</div>
    </div>
    <div>
      <div class="header">{{ 'statement.aviDiaryShort' | translate }}</div>
      <div>{{statement.aviDiary}}</div>
    </div>
    <button class="secondary" (click)="copyApplicationProperties(statement)">{{ 'statement.reload' | translate }}</button>
  </div>
  <div class="statement-title">{{ 'statement.statement' | translate }}</div>
  <hr>
  <app-statement-text-component [statement]="statement"></app-statement-text-component>
  <div class="signers-title">{{ 'statement.signers' | translate }}</div>
  <hr>
  <div class="signer-1st">
    <app-statement-signer [signer]="statement.firstSigner" [firstSigner]="true" (changedSigner)="signer1stSelectChange($event)"></app-statement-signer>
  </div>
  <div class="signer-2nd">
    <app-statement-signer [signer]="statement.secondSigner" [firstSigner]="false" (changedSigner)="signer2ndSelectChange($event)"></app-statement-signer>
  </div>
  <div class="buttons">
    <button type="submit" (click)="previewStatement()">{{ 'statement.previewStatement' | translate }}</button>
  </div>
  <div class="exit"><a href="javascript:void(0)" (click)="goBack()">{{ 'return' | translate }}</a></div>
</div>
