<div *ngIf="textOptions" class="statement-text-section">
  <div *ngIf="edit">
    <textarea [(ngModel)]="editableText" rows="15" cols="80"></textarea>
    <div class="buttons">
      <button type="submit" (click)="saveText()" class="save-button">{{ 'statement.save' | translate }}</button>
      <button type="submit" (click)="cancelEditing()">{{ 'statement.cancel' | translate }}</button>
      <a (click)="saveAsTemplate()">{{ 'statement.saveAsTemplate' | translate }}</a>
    </div>
  </div>
  <div *ngIf="!edit">
    <div class="statement-text" [innerHTML]="textOptions.length > 0?textOptions[selectedTextIndex].statementText:''"></div>
    <a (click)="modify()">{{ 'statement.edit' | translate }}</a>
  </div>
  <select class="statement-category" (ngModelChange)="textSelectChange($event)" [(ngModel)]=selectedTextIndex [disabled]="edit">
    <option *ngFor="let text of textOptions; let index = index" [ngValue]="index">{{text.statementCategory}}</option>
  </select>
</div>
