<div class="page" *ngIf="laboratory">
  <div class="page-content">
    <h1>{{ laboratory.properties.name }}, {{laboratory.properties.referenceNumber}}</h1>
    <div class="columns">
      <div>
        <div *ngFor="let application of laboratory.with.applications" class="box license">
          <div class="header">
            <h2 translate="application.license"></h2>
            <div class="diary">
              <div translate="application.applicationDiaryNumberAvi"></div>
              <div>{{ application.properties.aviDiary }}</div>
            </div>
          </div>
          <a
            [routerLink]="['/application', application.properties.id, 'contacts']"
            class="step"
            [ngClass]="{
              completed: phase.StatementRequestReceived < applicationService.phase(application),
              'in-progress': phase.StatementRequestReceived == applicationService.phase(application)
            }"
          >
            <div class="circle">
              <div class="circle-inner">1</div>
              <span class="check-mark">&#10004;</span>
            </div>
            <span class="description" translate="application.phases.StatementRequestReceived"></span>
          </a>
          <a
            [routerLink]="['/application', application.properties.id, 'produce']"
            class="step"
            [ngClass]="{
              completed: phase.ProduceStatementAndRegisterExtract < applicationService.phase(application),
              'in-progress': phase.ProduceStatementAndRegisterExtract == applicationService.phase(application)
            }"
          >
            <div class="circle">
              <div class="circle-inner">2</div>
              <span class="check-mark">&#10004;</span>
            </div>
            <span class="description" translate="application.phases.ProduceStatementAndRegisterExtract"></span>
          </a>
          <a
            [routerLink]="['/application', application.properties.id, 'validity']"
            class="step"
            [ngClass]="{
              completed: phase.RegisterConclusion < applicationService.phase(application),
              'in-progress': phase.RegisterConclusion == applicationService.phase(application)
            }"
          >
            <div class="circle">
              <div class="circle-inner">3</div>
              <span class="check-mark">&#10004;</span>
            </div>
            <span class="description" translate="application.phases.RegisterConclusion"></span>
          </a>
        </div>
      </div>
      <div>
        <div class="box">
          <h2 translate="laboratory.requirementsInformation"></h2>
          <a
            *ngIf="laboratory.with.validApplication; else notAvailable"
            [routerLink]="['/application', laboratory.with.validApplication.properties.id, 'register-extract']"
            translate="laboratory.showCurrentRegisterExtract"
          ></a>
          <ng-template #notAvailable>
            <p translate="laboratory.currentRegisterExtractNotAvailable"></p>
          </ng-template>
        </div>
        <div class="box">
          <app-laboratory-form [laboratory]="laboratory"></app-laboratory-form>
        </div>
      </div>
    </div>
  </div>
</div>
