import { Component, OnInit } from '@angular/core';
import { ApplicationService } from '../application/service/application.service';
import { ApplicationFind } from '../application/model/application-find';
import { Application } from '../application/model/application';
import { Observable } from 'rxjs';
import { ApplicationPhase } from '../application/enum/application-phase';
import { LaboratoryFindWith } from '../laboratory/model/laboratory-find-with';
import { PaginatorModule } from 'primeng/paginator';

export interface ApplicationResponse {
  items: Application[];
  count: number;
}

@Component({
  selector: 'app-internal-frontpage',
  templateUrl: './internal-frontpage.component.html',
  styleUrls: ['./internal-frontpage.component.scss']
})
export class InternalFrontpageComponent implements OnInit {
  phases = ApplicationPhase;
  applicationsResponse: Observable<ApplicationResponse>;
  applications: Application[];
  applicationsCurrentYear: Observable<Application[]>;
  applicationsCount: number;
  pageItems: number = 10;

  constructor(
    public applicationService: ApplicationService,
  ) { }

  ngOnInit() {

    const find = new ApplicationFind();
    find.with.laboratory = new LaboratoryFindWith();
    find.with.statement = true;
    find.orders = [{
      key: 'arrivalDate',
      dir: 'DESC'
    }];
    find.offset = 0;
    find.limit = this.pageItems;

    this.applicationsResponse = this.applicationService.findWithCount(find);
    this.applicationsResponse.subscribe(response => {
      this.applications = response.items;
      this.applicationsCount = response.count;
    });
  }

  onPageChange(event) {
    const find = new ApplicationFind();
    find.with.laboratory = new LaboratoryFindWith();
    find.with.statement = true;
    find.orders = [{
      key: 'arrivalDate',
      dir: 'DESC'
    }];
    find.offset = event.first;
    find.limit = event.rows;

    this.applicationsResponse = this.applicationService.findWithCount(find);
    this.applicationsResponse.subscribe(response => {
      this.applications = response.items;
      this.applicationsCount = response.count;
    });
    
  
  }
}

