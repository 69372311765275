import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {merge, Subject} from 'rxjs';
import {ResearchCombined} from '../../model/research-combined';
import {debounceTime, map, switchMap, tap} from 'rxjs/operators';
import {ResearchCombinedFind} from '../../model/research-combined-find';
import {ResearchCombinedFindWhere} from '../../model/research-combined-find-where';
import {ResearchService} from '../../service/research.service';
import {Research} from '../../model/research';
import {Util} from '../../../base/util';
import * as _ from 'lodash';
import {ResearchImport} from '../../service/research-import.service';
import {TranslateService} from '@ngx-translate/core';
import {MessageService} from 'primeng/api';
import { ApplicationFindWith } from '../../model/application-find-with';
import { Application } from '../../model/application';
import { ApplicationFind } from '../../model/application-find';
import { ApplicationService } from '../../service/application.service';

@Component({
  selector: 'app-application-researches-research-table',
  templateUrl: './application-researches-research-table.component.html',
  styleUrls: ['./application-researches-research-table.component.scss']
})
export class ApplicationResearchesResearchTableComponent implements OnInit {
  @Input()
  application: Application;

  @Input()
  formArray: UntypedFormArray;

  @Input()
  researches: Research[];

  @Input()
  showRedundantFields = true;

  @Output()
  researchRemoved = new EventEmitter<Research>();

  searchTerm: string;
  numberSearchTerms = new Subject<string>();
  abbreviationSearchTerms = new Subject<string>();
  nameSearchTerms = new Subject<string>();

  researchResults: ResearchCombined[] = [];
  activeResearchIndex: number;

  constructor(
    private researchService: ResearchService,
    public applicationService: ApplicationService,
    private formBuilder: UntypedFormBuilder,
    private translateService: TranslateService,
    private messageService: MessageService
  ) {}

  ngOnInit() {
    this.researches
      .map(this.buildResearchGroup.bind(this))
      .forEach((group: UntypedFormGroup) => this.formArray.push(group));

    merge(
      this.commonPipeForResearchSubjects(this.numberSearchTerms, (searchFind, term) =>
        searchFind.where.numberStartsWith = term
      ),
      this.commonPipeForResearchSubjects(this.abbreviationSearchTerms, (searchFind, term) =>
        searchFind.where.abbreviationStartsWith = term
      ),
      this.commonPipeForResearchSubjects(this.nameSearchTerms, (searchFind, term) =>
        searchFind.where.nameStartsWith = term
      )
    )
      .pipe(
        switchMap(searchFind => this.researchService.findCombined(searchFind))
      )
      .subscribe(researchResults => this.researchResults = researchResults);
  }

  copyResearches() {
    if (this.application) {
      const laboratoryId = this.application.properties.laboratoryId;
      const applicationFind = this.buildApplicationFind(laboratoryId);
      this.applicationService.find(applicationFind).subscribe(applications => {
        if (applications.length > 0) {
          this.removeCurrentApplication(applications);
          let latestResearches = this.getLatestResearches(applications);
          if (latestResearches) {
            latestResearches = this.removeIds(latestResearches);
            this.researches.push(...latestResearches);
            latestResearches
              .map(research => this.buildResearchGroup(research))
              .forEach(group => this.formArray.push(group));
          }
        }
      });
    }
  }

  buildApplicationFind(laboratoryId : number) : ApplicationFind {
    const applicationFind = new ApplicationFind();
    applicationFind.where.laboratoryIdIs = laboratoryId;
    applicationFind.orders = [{key: 'arrivalDate', dir: 'DESC NULLS LAST'}];
    applicationFind.with = new ApplicationFindWith();
    applicationFind.with.researches = true;
    return applicationFind;
  }

  private removeCurrentApplication(applications: Application[]) {
    applications.splice(applications.findIndex(application => application.properties.id === this.application.properties.id), 1);
  }

  getLatestResearches(applications: Application[]): Research[] | null {
    const appWithResearches = applications.find(app => app.with.researches && app.with.researches.length > 0);
    return appWithResearches ? appWithResearches.with.researches : null;
  }

  removeIds(researches : Research[]) : Research[] {
    return researches.map(research => {
      const { id, applicationId, subcontractId, ...remainingProperties } = research.properties;
      return {
        ...research,
        properties: remainingProperties
      };
    }) as Research[];
  }

  commonPipeForResearchSubjects(
    subject: Subject<string>,
    setupTerm: (find: ResearchCombinedFind, term: string) => void
  ) {
    return subject.pipe(
      tap(term => this.searchTerm = term),
      debounceTime(300),
      map(term => {
        const searchFind = new ResearchCombinedFind();
        searchFind.limit = 10;
        searchFind.where = new ResearchCombinedFindWhere();

        setupTerm(searchFind, term);

        return searchFind;
      }),
    );
  }

  buildResearchGroup(research: Research) {
    const group = this.formBuilder.group({
      number: ['', Validators.required],
      abbreviation: ['', Validators.required],
      name: ['', Validators.required],
      amount: ['', Validators.min(0)],
      qualityControl: [''],
      labQuality: ['', Validators.min(0)],
      ukneqas: ['', Validators.min(0)],
      qcmd: ['', Validators.min(0)],
      accredited: ['']
    });

    Util.entityPropertiesToForm(research, group);

    return group;
  }

  select(index: number, group: UntypedFormGroup, amountInput: HTMLInputElement) {
    if (index === this.researchResults.length) {
      return;
    }

    const research = this.researchResults[index];

    group.get('number').setValue(research.number);
    group.get('abbreviation').setValue(research.abbreviation);
    group.get('name').setValue(research.name);

    amountInput.focus();
  }

  addNewResearch() {
    this.addResearch(new Research());
  }

  addResearch(research: Research) {
    this.researches.push(research);
    this.formArray.push(this.buildResearchGroup(research));
  }

  remove(index: number) {
    const research = _.pullAt(this.researches, index)[0];
    if (research.properties.id) {
      this.researchRemoved.emit(research);
    }

    this.formArray.removeAt(index);
  }

  async importExcel(event: any) {
    let columns = [
      ResearchImport.numberColumn,
      ResearchImport.abbreviationColumn,
      ResearchImport.nameColumn ,
      ResearchImport.amountColumn
    ];

    if (this.showRedundantFields) {
      columns = columns.concat([
        ResearchImport.qualityControlColumn,
        ResearchImport.labQualityColumn,
        ResearchImport.ukneqasColumn,
        ResearchImport.qcmdColumn,
        ResearchImport.accreditedColumn
      ]);
    }

    const researchImport = await new ResearchImport().import(event.target.files[0], columns);

    if (researchImport.invalidColumnNames.length > 0) {
      this.messageService.add({
        severity: 'warn',
        summary: this.translateService.instant(
          'research.invalidColumnNamesOnImport',
          {columnNames: researchImport.invalidColumnNames.join(', ')}
        ),
        sticky: true
      });
    }

    if (researchImport.missingColumnNames.length > 0) {
      this.messageService.add({
        severity: 'warn',
        summary: this.translateService.instant(
          'research.missingColumnNamesOnImport',
          {columnNames: researchImport.missingColumnNames.join(', ')}
        ),
        sticky: true
      });
    }

    if (researchImport.errors.length > 0) {
      const messages = researchImport.errors.slice(0, 10).map(error => ({
        severity: 'error',
        summary: this.translateService.instant('research.errorOnImport', error),
        sticky: true
      }));

      this.messageService.addAll(messages);
      return;
    }

    this.researches.push(...researchImport.researches);

    researchImport.researches
      .map(research => this.buildResearchGroup(research))
      .forEach(group => this.formArray.push(group));
  }

  formGroups() {
    return this.formArray.controls as UntypedFormGroup[];
  }
}
