<app-application-header [application]="application"></app-application-header>
<div class="page">
  <div class="page-content">
    <div class="box" *ngIf="application && form">
      <form *ngIf="application" [formGroup]="form" (ngSubmit)="save()">
        <div *ngIf="supervisingApplication">
          <h2 translate="contact.inheritedContacts"></h2>
          <a [href]="suprevisingLaboratoryURL()" translate="contact.supervisingLaboratory"></a>
          <div>
            <div class="columns">
              <div *ngFor="let group of inheritedContactFormGroups; let i = index">
                <div *ngIf="inheritedContacts[i].properties.deleted == false">
                  <app-application-contact-form [form]="group"></app-application-contact-form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <h2 translate="contact.contactPersonsAndTheirInformation"></h2>
        <button (click)="addContact()" translate="contact.add"></button>
        <div class="columns">
          <div *ngFor="let group of contactFormGroups; let i = index">
            <div *ngIf="contacts[i].properties.deleted == false">
              <app-application-contact-form [form]="group"></app-application-contact-form>
              <button (click)="deleteContact(i)" translate="remove"></button>
            </div>
          </div>
        </div>
        <div>
          <h3 translate="contact.signerOfForm"></h3>
          <div class="stacked-input">
            <select [formControl]="signerFormControl">
              <option *ngFor="let contact of contacts" [ngValue]="contact.properties.name">{{contact.properties.name}}</option>
            </select>
          </div>
        </div>
        <h2 translate="contact.tallyAndManYear"></h2>
        <table>
          <thead>
          <tr>
            <th translate="contact.title"></th>
            <th class="input-cell" translate="contact.fullTimePersons"></th>
            <th class="input-cell" translate="contact.partTimePersons"></th>
            <th class="input-cell" translate="contact.manYearTotal"></th>
          </tr>
          </thead>
          <tbody>
          <ng-container *ngFor="let group of tallyFormGroups(); let index = index">
            <tr *ngIf="group.invalid">
              <td></td>
              <td colspan="3">
                <app-validation-error-message [control]="group"></app-validation-error-message>
              </td>
            </tr>
            <tr>
              <th [translate]="'contact.roles.' + tallies[index].properties.role"></th>
              <td class="input-cell">
                <input type="number" [formControl]="group.get('fullTime')" />
              </td>
              <td class="input-cell">
                <input type="number" [formControl]="group.get('partTime')" />
              </td>
              <td class="input-cell">
                <input type="number" [formControl]="group.get('manYears')" />
              </td>
            </tr>
          </ng-container>
          </tbody>
        </table>
        <div class="buttons">
          <button type="submit" translate="save" [disabled]="form.invalid"></button>
        </div>
      </form>
    </div>
  </div>
</div>
