<div class="page">
  <div class="page-content">
    <div class="header">
      <h1 translate="landing.headerTitle"></h1>
      <p class="size-24" translate="landing.headerSecondLine"></p>
    </div>
    <h2 translate="landing.applications"></h2>
    <div class="columns extra-pad">
      <div class="box in-progress">
        <h2 translate="landing.inProgress"></h2>
        <p translate="landing.inProgressDescription"></p>
        <table>
          <thead>
            <tr>
              <th translate="laboratory.laboratory"></th>
              <th translate="landing.phase"></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let application of applications | async">
              <td>
                <a
                  [id]="'in-progress-application-laboratory-' + application.with.laboratory.properties.id"
                  [routerLink]="['laboratory', application.with.laboratory.properties.id]"
                >
                  {{ application.with.laboratory.properties.name }}
                </a>
              </td>
              <td [translate]="'application.phases.' + phases[applicationService.phase(application)]"></td>
            </tr>
          </tbody>
        </table>
      </div>
      <div>
        <a class="button" [routerLink]="['application', 'basics']" translate="landing.addNewApplication"></a>
        <a class="button" style="margin-left: 5px" (click)="toggleSearchVisibility()" translate="Hae rekisterinumerolla"></a>
        <div [hidden]="isSearchToggled == false" class="box search">
          <h2 translate="landing.searchByReferenceNumberOrName"></h2>
          <input type="text" #input (input)="laboratorySearchTerms.next(input.value)" />
          <ul>
            <li *ngFor="let laboratory of laboratories | async">
                <a
                  [id]="'laboratory-search-' + laboratory.properties.id"
                  [routerLink]="['laboratory', laboratory.properties.id]"
                >{{ laboratory.properties.name }}</a>
              </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
