import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {LoginComponent} from './login/login.component';
import {LandingComponent} from './landing/landing.component';
import {AuthGuard} from './auth.guard';
import {ApplicationBasicsComponent} from './application/component/application-basics/application-basics.component';
import {ApplicationContactsComponent} from './application/component/application-contacts/application-contacts.component';
import {ApplicationResearchesComponent} from './application/component/application-researches/application-researches.component';
import { StatementComponent } from './statement/statement.component';
import { RequestForStatementComponent } from './statement/request-for-statement.component';
import { RegisterExtractComponent } from './register-extract/register-extract.component';
import { LaboratoryProfileComponent } from './laboratory/component/laboratory-profile/laboratory-profile.component';
import { LaboratorySearchComponent } from './laboratory/component/laboratory-search/laboratory-search.component'
import {ApplicationProduceComponent} from './application/component/application-produce/application-produce.component';
import {ApplicationValidityComponent} from './application/component/application-validity/application-validity.component';
import { InternalFrontpageComponent } from './internal-frontpage/internal-frontpage.component';

const routes: Routes = [
  { path: '', component: LandingComponent, canActivate: [AuthGuard]},
  { path: 'internal', component: InternalFrontpageComponent, canActivate: [AuthGuard]},
  { path: 'application/basics', component: ApplicationBasicsComponent, canActivate: [AuthGuard]},
  { path: 'application/:applicationId/basics', component: ApplicationBasicsComponent, canActivate: [AuthGuard]},
  { path: 'application/:applicationId/contacts', component: ApplicationContactsComponent, canActivate: [AuthGuard] },
  { path: 'application/:applicationId/researches', component: ApplicationResearchesComponent, canActivate: [AuthGuard] },
  { path: 'application/:applicationId/request-for-statement', component: RequestForStatementComponent, canActivate: [AuthGuard] },
  { path: 'application/:applicationId/statement', component: StatementComponent, canActivate: [AuthGuard] },
  { path: 'application/:applicationId/register-extract', component: RegisterExtractComponent, canActivate: [AuthGuard] },
  { path: 'application/:applicationId/produce', component: ApplicationProduceComponent, canActivate: [AuthGuard] },
  { path: 'application/:applicationId/validity', component: ApplicationValidityComponent, canActivate: [AuthGuard] },
  { path: 'laboratory/:laboratoryId', component: LaboratoryProfileComponent, canActivate: [AuthGuard] },
  { path: 'laboratory', component: LaboratorySearchComponent, canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
