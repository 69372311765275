import { Component, Input, OnInit, EventEmitter, Output , OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-searchable-drop-down',
  templateUrl: './searchable-drop-down.component.html',
  styleUrls: ['./searchable-drop-down.component.scss']
})
export class SearchableDropDownComponent implements OnInit {

  @Output() selectEvent = new EventEmitter<number>();

  @Input()
  items: any[];

  @Input()
  searchFields: string[];

  @Input()
  keyField: string;

  @Input()
  label: string;

  inputValue: string = '';
  filteredItems: any[];
  dropdownVisible: boolean = false;

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.items && changes.items.currentValue) {
      this.filterOptions();
    }
  }

  filterOptions() {
    this.filteredItems = this.items.filter(item => {
      let match = false;
      this.searchFields.forEach(searchField => {
        if (typeof item.properties[searchField] != 'string') {
          if (item.properties[searchField].toString().toLowerCase().startsWith(this.inputValue.toLowerCase())){
            match = true;
          }
        } else {
          if (item.properties[searchField].toLowerCase().startsWith(this.inputValue.toLowerCase())) {
            match = true;
          }
        }
      });
      return match;
    }
    );
    this.selectEvent.emit(-1);
  }

  selectOption(option: any) {
    this.inputValue = option.properties[this.searchFields[0]];
    this.selectEvent.emit(option);
  }

  onFocus() {
    this.dropdownVisible = true;
  }

  onBlur() {
    this.dropdownVisible = false;
  }
}
