/* src/app/application/component/application-basics/application-basics.component.scss */
.page-content > h2 {
  padding-top: 40px;
}
.method > * {
  width: 50%;
}
.method > *:first-child {
  border-right: 1px solid #C9C9C9;
}
.method .add-laboratory {
  display: flex;
  align-items: center;
}
.search-or-create {
  text-align: center;
}
.box {
  padding: 24px;
  margin-bottom: 24px;
}
.application > .columns > * {
  width: 33.33%;
}
.inspection-of-requirements {
  margin-top: 12px;
}
.application .buttons {
  margin-bottom: 40px;
  text-align: center;
}
.current-supervising-laboratory {
  display: flex;
}
.current-supervising-laboratory > *:first-child {
  flex-grow: 1;
}
/*# sourceMappingURL=application-basics.component.css.map */
