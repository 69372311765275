import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {UserService} from '../user/service/user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  // @ts-ignore
  @ViewChild('username')
  username: ElementRef;

  form: UntypedFormGroup;
  incorrect = false;

  logout = false;
  redirect: string;

  constructor(
    private userService: UserService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private formBuilder: UntypedFormBuilder
  ) {}

  ngOnInit() {
    this.activatedRoute.paramMap.subscribe(params => {
      this.logout = !! params.get('logout');
      this.redirect = params.get('redirect');
    });

    this.form = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });

    this.username.nativeElement.focus();
  }

  login() {
    const value = this.form.value;
    this.userService.login(value.username, value.password).subscribe(user => {
      if (! user) {
        this.incorrect = true;
        this.form.reset();
        this.username.nativeElement.focus();

        return;
      }

      if (! this.redirect) {
        this.router.navigate(['']);

        return;
      }

      // Navigate THL users to internal frontpage
      if (this.userService.isInternalUser()) {
        this.router.navigateByUrl('/internal');
        return;
      }

      this.router.navigateByUrl(this.redirect);
    });
  }
}
