<div class="page">
    <div class="page-content">
      <div class="header">
        <h1 translate="landing.headerTitle"></h1>
        <p class="size-24" translate="landing.headerSecondLine"></p>
      </div>
      <h2 translate="landing.applications"></h2>
        <div class="box table-wrapper">
          <table>
            <thead>
              <tr>
                <th translate="laboratory.laboratory"></th>
                <th translate="landing.phase"></th>
                <th translate="application.aviDiary"></th>
                <th translate="application.thlDiary"></th>
                <th translate="application.validUntil"></th>
                <th translate="application.statementDate"></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let application of applications">
                <td>
                  <a
                    [id]="'in-progress-application-laboratory-' + application.with.laboratory.properties.id"
                    [routerLink]="['/laboratory', application.with.laboratory.properties.id]"
                  >
                    {{ application.with.laboratory.properties.name }}
                  </a>
                </td>
                <td [translate]="'application.phases.' + phases[applicationService.phase(application)]"></td>
                <td>{{ application.properties.aviDiary }}</td>
                <td>{{ application.with.statement?.thlDiary }}</td>
                <td *ngIf="application.properties.validForNow; else validUntil">
                  {{ 'application.forNow' | translate }}
                </td>
                <ng-template #validUntil>
                  <td>{{ application.properties.validUntil | date:'dd.MM.yyyy' }}</td>
                </ng-template>
                <td>{{ application.with.statement?.statementDate | date:'dd.MM.yyyy' }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <p-paginator
          (onPageChange)="onPageChange($event)"
          [first]="0"
          [rows]="10"
          [totalRecords]="applicationsCount"
          [rowsPerPageOptions]="[10, 25, 50, 100]" />
    </div>
  </div>

