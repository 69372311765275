import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ApplicationService} from '../../service/application.service';
import {Application} from '../../model/application';
import {ApplicationFind} from '../../model/application-find';
import {Form, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ContactRole} from '../../enum/contact-role';
import {Contact} from '../../model/contact';
import {Tally} from '../../model/tally';
import {Util} from '../../../base/util';
import {ContactService} from '../../service/contact.service';
import {MessageService} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import {switchMap} from 'rxjs/operators';
import {EMPTY, of} from 'rxjs';
import {TallyService} from '../../service/tally-service';
import {
  fullTimeOrPartTimeMissingValidator,
  manYearsMissingValidator,
  manYearsTooHighValidator,
  manYearsTooLowValidator
} from '../../validator/tally.validator';
import {phoneNumberValidator} from '../../../base/validator';
import {TallyRole} from '../../enum/tally-role';
import {LaboratoryFindWith} from '../../../laboratory/model/laboratory-find-with';
import { LaboratoryFind } from '../../../laboratory/model/laboratory-find';
import { LaboratoryFindWhere } from '../../../laboratory/model/laboratory-find-where';
import { ApplicationFindWith } from '../../model/application-find-with';
import { LaboratoryService } from '../../../laboratory/service/laboratory.service';

@Component({
  templateUrl: './application-contacts.component.html',
  styleUrls: ['./application-contacts.component.scss']
})
export class ApplicationContactsComponent implements OnInit {
  form: UntypedFormGroup;
  ContactRole = ContactRole;

  application: Application;
  contacts: Contact[];
  supervisingApplication: Application;
  inheritedContacts: Contact[] = [];
  tallies: Tally[];

  constructor(
    private route: ActivatedRoute,
    private applicationService: ApplicationService,
    private laboratoryService: LaboratoryService,
    private formBuilder: UntypedFormBuilder,
    private contactService: ContactService,
    private tallyService: TallyService,
    private messageService: MessageService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    const applicationFind = new ApplicationFind();
    applicationFind.with.laboratory = new LaboratoryFindWith();
    applicationFind.with.contacts = true;
    applicationFind.with.tallies = true;

    this.route.params.subscribe(params =>
      this.applicationService.findById(params.applicationId, applicationFind).subscribe(application => {
        this.application = application;
        const category = this.application.properties.category;
        const supervisingLaboratoryId = this.application.properties.supervisingLaboratoryId;
        if ((category === 2 || category === 3) && supervisingLaboratoryId) {
          const supervisingLaboratoryFind = this.buildSupervisingLaboratoryFind(supervisingLaboratoryId);
          this.laboratoryService.find(supervisingLaboratoryFind).subscribe(supervisingLaboratories => {
            if(supervisingLaboratories.length > 0) {
              const supervisingLaboratory = supervisingLaboratories[0];
              this.supervisingApplication = supervisingLaboratory.with.validApplication;
            }
            this.finalizeNgOnInit();
          });
        } else {
          this.finalizeNgOnInit();
        }
      })
    );
  }

  buildSupervisingLaboratoryFind(supervisingLaboratoryId : number) : LaboratoryFind {
    const supervisingLaboratoryFind = new LaboratoryFind();
    supervisingLaboratoryFind.where = new LaboratoryFindWhere();
    supervisingLaboratoryFind.where.idIs = supervisingLaboratoryId;
    supervisingLaboratoryFind.with = new LaboratoryFindWith();
    supervisingLaboratoryFind.with.validApplication = new ApplicationFindWith();
    supervisingLaboratoryFind.with.validApplication.contacts = true;
    return supervisingLaboratoryFind;
  }

  finalizeNgOnInit() {
    this.prepareTallies();
    this.prepareContacts();
    this.buildForm();
  }

  prepareContacts() {
    this.contacts = this.application.with.contacts.map((contactProperties) => {
      const contact = new Contact();
      contact.properties = contactProperties;
      return contact;
    });

    if(this.supervisingApplication) {
      this.inheritedContacts = this.supervisingApplication.with.contacts.map((contactProperties) => {
        const contact = new Contact();
        contact.properties = contactProperties;
        return contact;
      });
    }
  }

  prepareTallies() {
    this.tallies = [
      TallyRole.ClinicalMicrobiologySpecialist,
      TallyRole.GraduatingClinicalMicrobiologySpecialist,
      TallyRole.HospitalMicrobiologist,
      TallyRole.GraduatingHospitalMicrobiologist,
      TallyRole.BioanalystOrLaboratoryAssistant,
      TallyRole.OtherLaboratoryContact
    ].map(role => {
      const tally = new Tally();
      const properties = this.application.with.tallies
        .find(findProperties => findProperties.role === role);

      if (properties) {
        tally.properties = properties;
      }

      tally.properties.applicationId = this.application.properties.id;
      tally.properties.role = role;
      return tally;
    });
  }

  buildForm() {
    this.form = this.formBuilder.group({
      signer: [this.application.properties.contactFormSigner],
      contacts: this.formBuilder.array(this.contacts.map(this.buildContactFormGroup.bind(this))),
      inheritedContacts: this.formBuilder.array(this.inheritedContacts.map(this.buildContactFormGroup.bind(this))),
      tallies: this.formBuilder.array(this.tallies.map(this.buildTallyFormGroup.bind(this)))
    });

    this.form.get('inheritedContacts')!.disable();
  }

  addContact() {
    const newContact = new Contact();
    newContact.properties.deleted = false;
    newContact.properties.applicationId = this.application.properties.id;
    newContact.properties.role = ContactRole.OtherLaboratoryContact;
    this.contacts.push(newContact);
    this.contactFormArray.push(this.buildContactFormGroup(newContact));
  }

  deleteContact(index: number) {
    this.contacts[index].properties.deleted = true;
    this.contactFormGroups[index] = this.buildContactFormGroup(this.contacts[index]);
  }

  buildContactFormGroup(contact: Contact) {
    const group = this.formBuilder.group({
      name: [],
      education: [],
      phone: ['', phoneNumberValidator],
      email: ['', Validators.email],
      role: []
    });

    Util.entityPropertiesToForm(contact, group);
    return group;
  }

  buildTallyFormGroup(tally: Tally) {
    const group = this.formBuilder.group({
      fullTime: [],
      partTime: [],
      manYears: []
    }, {validators: [
      manYearsMissingValidator,
      fullTimeOrPartTimeMissingValidator,
      manYearsTooLowValidator,
      manYearsTooHighValidator
    ]});

    Util.entityPropertiesToForm(tally, group);
    return group;
  }

  get signerFormControl(): UntypedFormControl {
    return this.form.get('signer') as UntypedFormControl;
  }

  get contactFormGroups(): UntypedFormGroup[] {
    return (this.form.get('contacts') as UntypedFormArray).controls as UntypedFormGroup[];
  }

  get inheritedContactFormGroups(): UntypedFormGroup[] {
    return (this.form.get('inheritedContacts') as UntypedFormArray).controls as UntypedFormGroup[];
  }

  get contactFormArray(): UntypedFormArray {
    return this.form.get('contacts') as UntypedFormArray;
  }

  suprevisingLaboratoryURL(): string {
    return 'laboratory/' + this.application.properties.supervisingLaboratoryId;
  }

  tallyFormGroups() {
    return (this.form.get('tallies') as UntypedFormArray).controls as UntypedFormGroup[];
  }

  save() {
    this.saveApplication().pipe(
      switchMap(() => this.saveContacts()),
      switchMap(() => this.saveTallies())
    ).subscribe(() => {

    });
  }

  saveApplication() {
    this.application.properties.contactFormSigner = this.form.get('signer')!.value;
    return this.applicationService.save(this.application.properties).pipe(
      switchMap(response => {
        if (! response.validation.valid) {
          this.messageService.add({
            severity: 'error',
            summary: this.translateService.instant('application.saveFailed')
          });

          return EMPTY;
        }

        this.messageService.add({
          severity: 'success',
          summary: this.translateService.instant('application.saveSucceed')
        });

        this.application.properties.contactFormSigner = response.properties.contactFormSigner;

        return of(response);
      })
    );
  }

  saveContacts() {
    const array = this.form.get('contacts') as UntypedFormArray;
    const groups = array.controls as UntypedFormGroup[];
    groups.forEach((group, index) => Util.formToEntityProperties(group, this.contacts[index]));

    return this.contactService
      .saveAll(this.contacts.map(contact => contact.properties))
      .pipe(switchMap(response => {
        if (! response.valid) {
          this.messageService.add({
            severity: 'error',
            summary: this.translateService.instant('contact.saveFailed')
          });

          groups.forEach((group, index) => Util.errorsToForm(response.responses[index], group));
          return EMPTY;
        }

        this.messageService.add({
          severity: 'success',
          summary: this.translateService.instant('contact.saveSucceed')
        });

        this.contacts.forEach((contact, index) => contact.properties = response.responses[index].properties);
        return of(response);
      }));
  }

  saveTallies() {
    const array = this.form.get('tallies') as UntypedFormArray;
    const groups = array.controls as UntypedFormGroup[];
    groups.forEach((group, index) => Util.formToEntityProperties(group, this.tallies[index]));

    return this.tallyService
      .saveAll(this.tallies.map(tally => tally.properties))
      .pipe(switchMap(response => {
        if (! response.valid) {
          this.messageService.add({
            severity: 'error',
            summary: this.translateService.instant('tally.saveFailed')
          });

          groups.forEach((group, index) => Util.errorsToForm(response.responses[index], group));
          return EMPTY;
        }

        this.messageService.add({
          severity: 'success',
          summary: this.translateService.instant('tally.saveSucceed')
        });

        this.tallies.forEach((tally, index) => tally.properties = response.responses[index].properties);
        return of(response);
      }));
  }
}
