import {UntypedFormGroup} from '@angular/forms';

export const manYearsMissingValidator = (control: UntypedFormGroup) => {
  const fullTime = control.get('fullTime').value;
  const partTime = control.get('partTime').value;
  const manYears = control.get('manYears').value;

  if (! manYears && (fullTime || partTime)) {
    return {'tally.manYearsMissing': true};
  }
};

export const fullTimeOrPartTimeMissingValidator = (control: UntypedFormGroup) => {
  const fullTime = control.get('fullTime').value;
  const partTime = control.get('partTime').value;
  const manYears = control.get('manYears').value;

  if (! fullTime && ! partTime && manYears) {
    return {'tally.fullTimeOrPartTimeMissing': true};
  }
};

export const manYearsTooLowValidator = (control: UntypedFormGroup) => {
  const fullTime = control.get('fullTime').value;
  const manYears = control.get('manYears').value;

  if (fullTime && manYears && manYears < fullTime) {
    return {'tally.manYearsTooLow': true};
  }
};

export const manYearsTooHighValidator = (control: UntypedFormGroup) => {
  let fullTime = control.get('fullTime').value;
  let partTime = control.get('partTime').value;
  const manYears = control.get('manYears').value;

  if (! fullTime) {
    fullTime = 0;
  }

  if (! partTime) {
    partTime = 0;
  }

  if (fullTime + partTime < manYears) {
    return {'tally.manYearsTooHigh': true};
  }
};
