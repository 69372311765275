import { Entity } from '../../base/model/entity';
import { LaboratoryProperties } from './laboratory-properties';
import { LaboratoryWith } from './laboratory-with';

export class Laboratory extends Entity<LaboratoryProperties, LaboratoryWith> {
  constructor() {
    super();

    this.properties = new LaboratoryProperties();
    this.with = new LaboratoryWith();
  }
}
