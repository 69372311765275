import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Service} from '../../base/service/service';
import {Contact} from '../model/contact';
import {ContactProperties} from '../model/contact-properties';
import {ContactSaveResponse} from '../model/contact-save-response';
import {SaveAllResponse} from '../../base/model/save-all-response';

@Injectable({
  providedIn: 'root'
})
export class ContactService extends Service<Contact> {
  constructor(
    public http: HttpClient
  ) {
    super(
      'api/contact',
      http
    );
  }

  save(properties: ContactProperties) {
    return this.http.post<ContactSaveResponse>(this.baseUrl + '/save', properties);
  }

  saveAll(propertiesList: ContactProperties[]) {
    return this.http.post<SaveAllResponse<ContactSaveResponse>>(this.baseUrl + '/saveAll', propertiesList);
  }
}
